import React, { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../config/Links";
import Loading from "../../assets/loading.svg";
import Refresh from "../../assets/rff.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qs from "qs";
import { FaUserPlus } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { MdOutlinePhoneEnabled } from "react-icons/md";
import { IoLockOpenOutline } from "react-icons/io5";
import { PiBuildingOffice } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";
import { FaRegEye } from "react-icons/fa6";
import { FaRegEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { IoIosRefresh } from "react-icons/io";

function CreateControleur() {
  const navigation = useNavigate();
  const url = Config.G_PERFORM_LINK;
  const gesUrl = Config.GESCAPO_LINK;
  const token = sessionStorage.getItem("gestoken");
  const token1 = sessionStorage.getItem("admin_tk");

  // Generate
  const avl = "checker@";
  const makeid = (length) => {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return avl + result;
  };

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [pass, setPass] = useState(makeid(4));
  const [agence, setAgence] = useState([]);
  const [select, setSelect] = useState(0);
  const [selectName, setSelectName] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState();

  // FETCH ALL AGENCE
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${gesUrl}/api/auth/admin/agence/list?all=true`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchAgenceListe = async () => {
      await axios
        .request(config)
        .then((response) => {
          setAgence(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAgenceListe();
  }, []);

  // Send Request
  let data = qs.stringify({
    nom: name,
    numero_de_telephone: number,
    mot_de_passe: pass,
    confirmPass: pass,
    agence: select,
    agence_name: selectName,
  });

  let config2 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/controleur/signup`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token1}`,
    },
    data: data,
  };

  const createControleur = async () => {
    await axios
      .request(config2)
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          setTimeout(() => {
            setLoading1(false);
            navigation(-1);
          }, 1500);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setLoading1(false);
        } else if (error.response.status === 403) {
          toast.error(error.response.data.message);
          setLoading1(false);
        } else {
          toast.error("Erreur de creation de compte !");
          console.log(error);
          setLoading1(false);
        }
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  return (
    <>
      <div
        onClickCapture={() => setOpen(false)}
        className="flex justify-center items-center mt-[70px] px-[30%] max-sm:px-0"
      >
        <div className="flex w-full h-[90vh] bg-[#ADADAD36] rounded-xl max-sm:bg-transparent flex-col">
          {/* Header */}
          <div className="flex flex-row items-center w-full justify-center">
            {/* Add New Button */}
            <div className="flex flex-row h-20 items-center px-3">
              <FaUserPlus size={30} color="#fff" />
              <p className="text-white text-xl font-bold ml-3">
                Ajouter un nouveau Contrôleur
              </p>
            </div>
          </div>

          {/* Forms */}
          <div className="px-[20%] max-sm:px-[10%]">
            {/* Nom */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <FiUser size={30} color="#fff" />
              <input
                required
                type="text"
                placeholder="Nom du contrôleur"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* Phone */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <MdOutlinePhoneEnabled size={30} color="#fff" />
              <input
                required
                maxLength={8}
                placeholder="Numéro de téléphone"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </div>

            {/* Password */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <IoLockOpenOutline size={30} color="#fff" />
              <input
                disabled
                required
                type={showPass ? "text" : "password"}
                placeholder="Crée un mot de passe"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />

              <div className="flex flex-row w-[100px] justify-between">
                {/* Generate Passe */}
                <button onClick={() => setShowPass(!showPass)}>
                  {showPass ? (
                    <FaRegEyeSlash color="white" />
                  ) : (
                    <FaRegEye color="white" />
                  )}
                </button>

                {/* Generate Passe */}
                <button
                  onClick={() => setPass(makeid(4))}
                  className="hover:bg-slate-400 p-2 rounded-full"
                >
                  <IoIosRefresh color="#fff" />
                </button>
              </div>
            </div>

            {/* Password Confirme */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <IoLockOpenOutline size={30} color="#fff" />
              <input
                disabled
                required
                type={showPass ? "text" : "password"}
                placeholder="Confirmer le mot de passe"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
            </div>

            {/* Agence */}
            <button
              onClick={() => setOpen(!open)}
              className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4 w-full justify-between"
            >
              <div className="flex justify-between items-center">
                <PiBuildingOffice size={30} color="#fff" />
                <p className="text-white ml-3">
                  {selectName || "Selectionner l'Agence"}
                </p>
              </div>

              <IoIosArrowDown size={20} color="#fff" />
            </button>

            {/* Creat Controleur button */}
            <div className="flex justify-center items-center mt-10">
              {loading1 ? (
                <img src={Loading} className="w-10 h-10" />
              ) : (
                <button
                  onClick={() => {
                    setLoading1(!loading1);
                    createControleur();
                  }}
                  disabled={
                    name.length == 0 ||
                    number.length == 0 ||
                    pass.length == 0 ||
                    select.length == 0 ||
                    selectName.length == 0
                  }
                  className={
                    name.length == 0 ||
                    number.length == 0 ||
                    pass.length == 0 ||
                    select.length == 0 ||
                    selectName.length == 0
                      ? "bg-blue-900 p-3 w-[300px] rounded-xl opacity-60"
                      : "bg-blue-900 p-3 w-[300px] rounded-xl hover:bg-slate-900"
                  }
                >
                  <p className="text-white">Crée le contrôleur</p>
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Selected Agence */}
        <div
          className={
            !open
              ? "hidden transition-all"
              : "absolute w-[300px] flex flex-col  items-center bg-black max-sm:bg-slate-500 rounded-xl max-h-[60vh] max-sm:h-[200px] mb-2 max-sm:mb-20 bottom-0 transition"
          }
        >
          <div className="flex flex-row items-center h-[30px]">
            <PiBuildingOffice size={20} color="#fff" className="mt-2" />
            <p className="text-white text-lg font-semibold ml-3 mt-2">
              List des Agences
            </p>
          </div>

          {/* Divider */}
          <div className="w-full h-[1px] bg-slate-300 my-2" />

          {/* Body */}
          <div className="overflow-auto h-[70vh] scrollbar-hide flex flex-col">
            {loading ? (
              <div className="w-full h-full flex flex-col justify-center items-center p-3">
                <img src={Refresh} className="w-12 h-12" />
                <p className="text-white">Recherche Agence...</p>
              </div>
            ) : (
              agence.length > 0 &&
              agence &&
              agence.map(({ id, nom_agence }) => (
                <button
                  key={id}
                  onClick={() => {
                    setSelect(id);
                    setSelectName(nom_agence);
                    setOpen(false);
                  }}
                  className={
                    select === id
                      ? "bg-slate-700 p-2"
                      : "hover:bg-slate-700 p-2"
                  }
                >
                  <p className="text-white">{nom_agence}</p>
                </button>
              ))
            )}
          </div>
        </div>

        {/* Toastify */}
        <ToastContainer />
      </div>
    </>
  );
}

export default CreateControleur;
