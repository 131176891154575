import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TiUserOutline } from "react-icons/ti";
import Config from "../../config/Links";
import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineAttachMoney } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { GrGroup } from "react-icons/gr";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";

// Clients Liste Render
import AllClientRender from "../client/ClientList";
import ClientNotDeliveredRender from "../client/ClientNotDeleiver";
import ClientDeliveredRender from "../client/ClientDeleiver";

const url = Config.GESCAPO_LINK;
const url2 = Config.G_PERFORM_LINK;
const token = sessionStorage.getItem("gestoken");
const token2 = sessionStorage.getItem("admin_tk");

// const url = Config.G_PERFORM_LINK;
const geSUrl = Config.GESCAPO_LINK;

const menu = [
  { id: 0, title: "Tous" },
  { id: 1, title: "Non Livré" },
  { id: 2, title: "Livré" },
];

function ColecteurInfo() {
  moment.locale("fr");
  const { id } = useParams();
  const [colInfo, setColInfo] = useState("--");
  const [deversementG, setDeversementG] = useState("--");
  const [selectMenu, setSelectMenu] = useState(0);
  const [clt, setClt] = useState([]);

  //   Request Colector info
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/api/auth/admin/agent_collecteur/info/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchColInfo = async () => {
      await axios
        .request(config)
        .then((response) => {
          setColInfo(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchColInfo();
  }, []);

  // Deversement Globale
  let config2 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/api/auth/admin/agent_collecteur/deversement/global/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchGlobDeversement = async () => {
      await axios
        .request(config2)
        .then((response) => {
          setDeversementG(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchGlobDeversement();
  }, [id]);

  // Client Status
  let config6 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/api/auth/admin/agent_collecteur/client/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchClt = async () => {
      await axios
        .request(config6)
        .then((response) => {
          setClt(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchClt();
  }, []);

  return (
    <div className="flex w-full flex-col mt-[60px]">
      {/* Header */}
      <div className="w-full flex h-30 flex-row z-10 bg-black items-center justify-between px-4 max-sm:px-0">
        {/* Left */}
        <div className="flex flex-row">
          <TiUserOutline size={50} color="#fff" />
          <div>
            <p className="text-white font-bold">{colInfo.nom}</p>
            <p className="text-white">{colInfo.prenom}</p>

            {/* Salary */}
            <div className="flex flex-row ">
              {/* Product Salary */}
              <div>
                <span className="font-bold text-blue-700 text-xl">
                  Salaire produit
                </span>

                <Link to={`/dashboard/payment/pending/produit/col/${id}`}>
                  <div className="p-2 bg-slate-700 hover:bg-slate-900 flex items-center justify-center rounded-md">
                    <p className="text-white text-lg">Detail</p>
                  </div>
                </Link>

                {/* Day */}
                {/* <div className=" flex flex-row items-center">
                  <div className="flex flex-row items-center">
                    <MdOutlineAttachMoney size={20} color="#fff" />
                    <p className="text-white">Jour : </p>
                  </div>

                  <p className="text-white ml-2 ">
                    rien {""}
                    FCFA
                  </p>
                </div> */}

                {/* Globale */}
                {/* <div className=" flex flex-row items-center">
                  <div className="flex flex-row items-center">
                    <GrMoney size={20} color="#fff" />
                    <p className="text-white ml-2">Mois : </p>
                  </div>

                  <p className="text-white ml-2">
                    {totalCommission} {""}
                    FCFA
                  </p>
                </div> */}
              </div>

              {/* Epargne Salary */}
              <div className="ml-5">
                <span className="font-bold text-blue-700 text-xl">
                  Salaire épargne
                </span>

                <Link to={`/dashboard/payment/pending/epargne/col/${id}`}>
                  <div className="p-2 bg-slate-700 hover:bg-slate-900 flex items-center justify-center rounded-md">
                    <p className="text-white text-lg">Detail</p>
                  </div>
                </Link>

                {/* Day */}
                {/* <div className=" flex flex-row items-center">
                  <div className="flex flex-row items-center">
                    <MdOutlineAttachMoney size={20} color="#fff" />
                    <p className="text-white">Jour : </p>
                  </div>

                  <p className="text-white ml-2 ">---- FCFA</p>
                </div> */}

                {/* Globale */}
                {/* <div className=" flex flex-row items-center">
                  <div className="flex flex-row items-center">
                    <GrMoney size={20} color="#fff" />
                    <p className="text-white ml-2">Mois : </p>
                  </div>

                  <p className="text-white ml-2">
                    {totalCommission} {""} FCFA
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* Center */}
        {/* Menu */}
        <div className="max-sm:hidden w-5/12 flex flex-col justify-arround px-10 mr-[70px]">
          {/* Nbr Client */}
          <div className="flex flex-row items-center justify-center my-1">
            <GrGroup size={20} color="#fff" />
            <p className="text-white font-bold ml-3">Liste des clients</p>
          </div>
          <div className="bg-[#ADADAD36] w-full p-1.5 flex flex-row rounded-full justify-between  items-center">
            {menu.map(({ id, title }) => (
              <button
                onClick={() => setSelectMenu(id)}
                key={id}
                className={
                  selectMenu === id
                    ? "bg-blue-500 p-1.5 rounded-full w-[150px] flex flex-row justify-center"
                    : "bg-blue-tansparent p-1.5 rounded-full w-[150px] flex flex-row justify-center"
                }
              >
                <p className="text-white">{title}</p>
                <p className="text-red-600 ml-2">
                  {id === 0
                    ? colInfo.nbr_cli
                    : id === 1
                      ? clt.reduce((a, c) => a + c.nbr_carnet_finished, 0)
                      : id === 2 &&
                        clt.reduce((a, c) => a + c.nbr_carnet_delivered, 0)}
                </p>
              </button>
            ))}
          </div>
        </div>

        {/* Right */}
        <div className="w-[180px]">
          {/* Phone */}
          <div className="flex flex-row items-center">
            <MdOutlinePhone size={20} color="#fff" />
            <p className="text-white font-bold ml-3">(+228) {colInfo.numero}</p>
          </div>

          {/* Day */}
          <div className="flex flex-row items-center">
            <MdOutlineAttachMoney size={20} color="#fff" />
            <p className="text-green-400 font-bold ml-3">
              {" "}
              {colInfo.compte_agent_collecteur?.toLocaleString("FR-fr")} F
            </p>
          </div>

          {/* Globale */}
          <div className="flex flex-row items-center">
            <GrMoney size={20} color="#fff" />
            <p className="text-green-400 font-bold ml-3">
              {" "}
              {deversementG.toLocaleString("FR-fr")} F
            </p>
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="flex w-full h-[72vh]">
        {selectMenu === 0 ? (
          <div className="flex w-full justify-center items-center">
            <AllClientRender col_id={id.toString()} />
          </div>
        ) : selectMenu === 1 ? (
          <div className="flex w-full justify-center items-center">
            <ClientNotDeliveredRender col_id={id.toString()} />
          </div>
        ) : (
          selectMenu === 2 && (
            <div className="flex w-full justify-center items-center">
              <ClientDeliveredRender col_id={id.toString()} />
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default ColecteurInfo;
