import React from "react";
import LitigeComponent from "../../components/litige/Litige";

function Litige() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <LitigeComponent />
    </div>
  );
}

export default Litige;
