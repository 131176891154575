import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";

export default function BonusListesLgg() {
  const [nameSearch, setNameSearch] = useState("");

  return (
    <div className="py-[60px] w-full flex-col  max-sm:px-2">
      {/* Header */}
      <div className="w-full mb-2 flex flex-row items-center justify-end px-3 max-sm:px-0">
        {/* Searchbar */}
        <div
          title="Recherche"
          className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
        >
          <CiSearch size={25} color="white" />
          <input
            type="search"
            placeholder="filtrage des bonuses"
            className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
            value={nameSearch}
            onChange={(e) => setNameSearch(e.target.value)}
          />
        </div>
      </div>

      {/* Body */}
      <div></div>
    </div>
  );
}
