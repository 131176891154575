import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import Config from "../../config/Links";
import { Link } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa6";
import { BsBuildings } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineUsers } from "react-icons/hi2";
import { LuUsers2 } from "react-icons/lu";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Loading_blue from "../../assets/svg/loading_blue.json";
import { PiCardsThreeLight } from "react-icons/pi";
import moment from "moment";
import "moment/locale/fr";

// Coll Rank menu
const carnetMenu = [
  { id: 0, title: "24 heures" },
  { id: 1, title: "Semaine" },
  { id: 2, title: "Mois" },
  { id: 3, title: "90 Jours" },
];

const ordersMenu = [
  { id: 0, title: "Par Carnet vendu" },
  { id: 1, title: "Par Déversement" },
];

export default function AgenceListe() {
  // A Day
  const day = moment().subtract(1, "days").format("YYYY-MM-DD");

  // A week
  const week = moment().subtract(7, "days").format("YYYY-MM-DD");

  // A mounth
  const mounth = moment().subtract(1, "months").format("YYYY-MM-DD");

  // A 90 days
  const ninetyDays = moment().subtract(3, "months").format("YYYY-MM-DD");

  const [select, setSelect] = useState(0);
  const [select2, setSelect2] = useState(0);
  const [agenceData, setAgenceData] = useState([]);
  const [colData, setColData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);
  const [loading5, setLoading5] = useState(true);
  const [loading6, setLoading6] = useState(true);
  const [bestCarnet, setBestCarnet] = useState([]);
  const [filter, setFilter] = useState(day);

  // Fetch data Agence && Collecteurs
  useEffect(() => {
    const fetchData = async () => {
      const gesToken = sessionStorage.getItem("gestoken");
      const headers = {
        Authorization: `Bearer ${gesToken}`,
      };

      try {
        const [agenceResponse, collecteursResponse] = await Promise.all([
          // Agence
          axios.post(
            `${Config.GESCAPO_LINK}/api/auth/admin/agence/list?all=true`,
            {},
            { headers },
          ),

          // Collecteurs
          axios.post(
            `${Config.GESCAPO_LINK}/api/auth/admin/agent_collecteur/list?all=true`,
            {},
            { headers },
          ),
        ]);
        setLoading(false);
        setAgenceData(agenceResponse.data.data);
        setColData(collecteursResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const getCollecteurCount = (agenceId) => {
    return colData.filter((cc) => cc.id_agence === agenceId).length;
  };

  const sortedAgenceData = useMemo(() => {
    return [...agenceData].sort((a, b) => {
      const countA = getCollecteurCount(a.id);
      const countB = getCollecteurCount(b.id);
      return countB - countA;
    });
  }, [agenceData, colData]);

  // Dates
  const now = moment().format("YYYY-MM-DD");

  // Best Coll By Aell Carnet
  useEffect(() => {
    const getBestCarnet = async () => {
      const token = sessionStorage.getItem("gestoken");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Config.GESCAPO_LINK}/api/auth/admin/v4/ranked-carnets/by-date-range?
        startDate=${filter}-15&endDate=${now}&all=true`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setLoading1(false);
          setLoading2(false);
          setLoading3(false);
          setLoading4(false);
          setBestCarnet(response.data.data);
        })
        .catch((error) => {
          setLoading1(true);
          setLoading2(true);
          setLoading3(true);
          setLoading4(true);
          console.log(error);
        });
    };
    getBestCarnet();
  }, [filter]);

  // Best Coll By Déversement
  return (
    <>
      <div className="grid grid-cols-2 max-sm:grid-cols-1 w-9/12 max-sm:items-center max-sm:mb-[100px] items-start w-full justify-between px-5">
        {/* Best Carnets Sell */}
        <div className="w-11/12 max-sm:w-full">
          {/* Title */}
          <span className="flex flex-row items-center mb-4">
            <PiCardsThreeLight size={28} color="#fff" />
            <h2 className="text-white font-bold text-2xl ml-3">
              Carnets les plus vendus
            </h2>
          </span>

          {/* Body */}
          <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
            {/* Navigation */}
            <div className="bg-gray-700 p-4">
              <div className="flex rounded-full bg-gray-600 p-1">
                {carnetMenu.map((menu) => (
                  <button
                    key={menu.id}
                    onClick={() => {
                      setSelect(menu.id);
                      if (menu.id === 0) {
                        setFilter(day);
                      } else if (menu.id === 1) {
                        setFilter(week);
                      } else if (menu.id === 2) {
                        setFilter(mounth);
                      } else if (menu.id === 3) {
                        setFilter(ninetyDays);
                      }
                    }}
                    className={`flex-1 py-2 px-4 rounded-full transition-colors duration-200 ${
                      menu.id === select
                        ? "bg-blue-500 text-white"
                        : "text-gray-300 hover:bg-gray-500"
                    }`}
                  >
                    {menu.title}
                  </button>
                ))}
              </div>
            </div>

            {/* Content */}
            <div className="p-6 overflow-auto h-[550px]">
              {select === 0 &&
                (loading1 ? (
                  <Player
                    autoplay
                    loop
                    src={Loading_blue}
                    style={{ height: "100px", width: "100px" }}
                  />
                ) : (
                  <div className="text-white">
                    {/* Add content for "Carnet vendu" here */}
                    {bestCarnet.map((item, index) => (
                      <div
                        key={item.id}
                        className="flex items-center bg-slate-700 my-2 p-2 rounded-md"
                      >
                        {/* Left Info */}
                        <div className="rounded-full mx-5 p-1 bg-green-600 w-[45px] h-[45px] flex justify-center items-center">
                          <h2># {index + 1}</h2>
                        </div>

                        {/* Right Info */}
                        <div className="w-9/12">
                          <h2 className="font-bold">{item.libelle}</h2>
                          <p> Mise : {item.tarif} F</p>
                          <p className="text-green-500">
                            Total vendus : {item.totalVendu}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}

              {select === 1 &&
                (loading2 ? (
                  <Player
                    autoplay
                    loop
                    src={Loading_blue}
                    style={{ height: "100px", width: "100px" }}
                  />
                ) : (
                  <div className="text-white">
                    {/* Add content for "Carnet vendu" here */}
                    {bestCarnet.map((item, index) => (
                      <div
                        key={item.id}
                        className="flex items-center bg-slate-700 my-2 p-2 rounded-md"
                      >
                        {/* Left Info */}
                        <div className="rounded-full mx-5 p-1 bg-green-600 w-[45px] h-[45px] flex justify-center items-center">
                          <h2># {index + 1}</h2>
                        </div>

                        {/* Right Info */}
                        <div className="w-9/12">
                          <h2 className="font-bold">{item.libelle}</h2>
                          <p> Mise : {item.tarif} F</p>
                          <p className="text-green-500">
                            Total vendus : {item.totalVendu}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}

              {select === 2 &&
                (loading3 ? (
                  <Player
                    autoplay
                    loop
                    src={Loading_blue}
                    style={{ height: "100px", width: "100px" }}
                  />
                ) : (
                  <div className="text-white">
                    {/* Add content for "Carnet vendu" here */}
                    {bestCarnet.map((item, index) => (
                      <div
                        key={item.id}
                        className="flex items-center bg-slate-700 my-2 p-2 rounded-md"
                      >
                        {/* Left Info */}
                        <div className="rounded-full mx-5 p-1 bg-green-600 w-[45px] h-[45px] flex justify-center items-center">
                          <h2># {index + 1}</h2>
                        </div>

                        {/* Right Info */}
                        <div className="w-9/12">
                          <h2 className="font-bold">{item.libelle}</h2>
                          <p> Mise : {item.tarif} F</p>
                          <p className="text-green-500">
                            Total vendus : {item.totalVendu}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}

              {select === 3 &&
                (loading4 ? (
                  <Player
                    autoplay
                    loop
                    src={Loading_blue}
                    style={{ height: "100px", width: "100px" }}
                  />
                ) : (
                  <div className="text-white">
                    {/* Add content for "Carnet vendu" here */}
                    {bestCarnet.map((item, index) => (
                      <div
                        key={item.id}
                        className="flex items-center bg-slate-700 my-2 p-2 rounded-md"
                      >
                        {/* Left Info */}
                        <div className="rounded-full mx-5 p-1 bg-green-600 w-[45px] h-[45px] flex justify-center items-center">
                          <h2># {index + 1}</h2>
                        </div>

                        {/* Right Info */}
                        <div className="w-9/12">
                          <h2 className="font-bold">{item.libelle}</h2>
                          <p> Mise : {item.tarif} F</p>
                          <p className="text-green-500">
                            Total vendus : {item.totalVendu}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* Col Ranks */}
        <div className="w-11/12 max-sm:w-full">
          {/* Title */}
          <span className="flex flex-row items-center mb-4">
            <LuUsers2 size={28} color="#fff" />
            <h2 className="text-white font-bold text-2xl ml-3">
              Meilleurs Collecteurs (Mois)
            </h2>
          </span>

          {/* Body */}
          <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
            {/* Navigation */}
            <div className="bg-gray-700 p-4">
              <div className="flex rounded-full bg-gray-600 p-1">
                {ordersMenu.map((ordermenu) => (
                  <button
                    key={ordermenu.id}
                    onClick={() => setSelect2(ordermenu.id)}
                    className={`flex-1 py-2 px-4 rounded-full transition-colors duration-200 ${
                      ordermenu.id === select2
                        ? "bg-blue-500 text-white"
                        : "text-gray-300 hover:bg-gray-500"
                    }`}
                  >
                    {ordermenu.title}
                  </button>
                ))}
              </div>
            </div>

            {/* Content */}
            <div className="p-6 overflow-auto h-[550px]">
              {select2 === 0 &&
                (loading5 ? (
                  <Player
                    autoplay
                    loop
                    src={Loading_blue}
                    style={{ height: "100px", width: "100px" }}
                  />
                ) : (
                  <div className="text-white">
                    {/* Add content for "Carnet vendu" here */}
                    Meilleurs Collecteur par Carnets Vendus du mois
                  </div>
                ))}

              {select2 === 1 &&
                (loading6 ? (
                  <Player
                    autoplay
                    loop
                    src={Loading_blue}
                    style={{ height: "100px", width: "100px" }}
                  />
                ) : (
                  <div className="text-white">
                    {/* Add content for "Déversement" here */}
                    Meilleurs Collecteur par deversement du mois
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* Agence */}
        <div className="w-11/12 max-sm:w-full">
          {/* Title */}
          <span className="flex flex-row my-2">
            <FaRegBuilding size={25} color="#fff" />
            <p className="text-white font-bold text-2xl ml-2">
              Liste des Agences ({agenceData.length})
            </p>
          </span>

          {/* Body */}
          {loading ? (
            <div className="overflow-auto grid grid-cols-3 bg-transparent rounded-md my-3 w-full h-auto scrollbar-hide">
              {Array.from({ length: 13 }).map((_, index) => (
                <div
                  key={index}
                  className="bg-[#ffffff24] p-3 m-2 h-[95px] rounded-xl w-[200px] shadow-md animate-pulse"
                />
              ))}
            </div>
          ) : (
            <div className="overflow-auto grid grid-cols-3 max-sm:grid-cols-2 h-[auto] bg-[#ffffff14] rounded-md my-3 w-full scrollbar-hide">
              {sortedAgenceData.map((agence) => (
                <Link
                  key={agence.id}
                  to={`/dashboard/colecteur/agence/info/${agence.id}`}
                >
                  <div className="bg-gray-800 p-3 m-2 rounded-xl shadow-md hover:bg-slate-900">
                    <div className="flex items-center">
                      <BsBuildings size={20} color="white" />
                      <h3 className="text-white ml-2">{agence.nom_agence}</h3>
                    </div>

                    <div className="flex items-center mt-2">
                      <HiOutlineLocationMarker size={20} color="white" />
                      <p className="text-green-500 ml-2">
                        {agence.quartier.libelle}
                      </p>
                    </div>

                    <div className="flex items-center mt-2">
                      <HiOutlineUsers size={20} color="white" />
                      <p className="text-yellow-500 ml-2">
                        {getCollecteurCount(agence.id)} Collecteurs
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
