import React, { useEffect, useState } from "react";
import { MdOutlineExposurePlus1 } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { BsCartPlus } from "react-icons/bs";
import { LuPlus } from "react-icons/lu";
import { CiSearch } from "react-icons/ci";
import { CiDeliveryTruck } from "react-icons/ci";
import { CgInfo } from "react-icons/cg";
import { MdOutlineHistoryToggleOff } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { GoPlus } from "react-icons/go";
import { RiCheckDoubleLine } from "react-icons/ri";
import Load from "../../assets/rff.svg";
import Nothing from "../../assets/svg/order.svg";
import { FaChartPie } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheckDouble } from "react-icons/fa";
import { RiBuilding2Line } from "react-icons/ri";
import qs from "qs";
import axios from "axios";
import Config from "../../config/Links";
import moment from "moment";
import "moment/locale/fr";
import Shop from "../../assets/shop.png";
import ProviderIco from "../../assets/free-shipping.png";

// History Menu
const historyMenu = [
  { id: 0, title: "Entrés" },
  { id: 1, title: "Sortis" },
];

export default function StockGListe() {
  moment.locale("fr");
  const [select, setSelect] = useState(0);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal6, setModal6] = useState(false);
  const [modal7, setModal7] = useState(false);

  // Modal States
  // Get Product
  const [searchP, setSearchP] = useState("");
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  // Info
  const [pName, setPName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [date, setDate] = useState("");

  // Get All Product Liste
  const fetchAllProducts = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/get/all/sg/products?query=${searchP}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setNotFound(false);
        setProduct(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setNotFound(true);
          setLoading(false);
        }
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAllProducts();
  }, [searchP]);

  // Create
  const [nom, setNom] = useState("");
  const [quantite, setQuantite] = useState("");
  const [prix_unitaire, setPrix_unitaire] = useState("");

  // Create New Product
  const createNewProduct = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      nom: nom,
      quantite: quantite,
      prix_unitaire: prix_unitaire,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/add/new/products/sg`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        setNom("");
        setQuantite("");
        setPrix_unitaire("");
        setModal(false);
        fetchAllProducts();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Create New Provider
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");

  const createNewProvider = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      name: name,
      location: location,
      phone: phone,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/create/new/provider`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        setName("");
        setLocation("");
        setPhone("");
        setModal1(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error.response.data.message);
      });
  };

  // Get All Providers
  const [providers, setProviders] = useState([]);
  const [loading1, setLoading1] = useState(true);

  useEffect(() => {
    fetchAllProviders();
  }, []);

  const fetchAllProviders = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/get/all/the/providers`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setLoading1(false);
        setProviders(response.data.data);
      })
      .catch((error) => {
        setLoading1(false);
        console.log(error);
      });
  };

  // Get product Add History Liste
  const [addHistories, setAddHistories] = useState([]);
  const [dateSearch, setDateSearch] = useState("");
  const [notFound2, setNotFound2] = useState(false);

  const fetchAddHistoryListe = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/get/products/add/history/liste?query=${dateSearch}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setNotFound2(false);
        setAddHistories(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setNotFound2(true);
        }
        console.log(error);
      });
  };
  useEffect(() => {
    fetchAddHistoryListe();
  }, [dateSearch]);

  // Get product Add History Liste
  const [removeHistories, setRemoveHistories] = useState([]);
  const [notFound3, setNotFound3] = useState(false);

  const fetchRemoveHistoryListe = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/get/products/remove/history/liste?query=${dateSearch}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setNotFound3(false);
        setRemoveHistories(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setNotFound3(true);
        }
        console.log(error);
      });
  };
  useEffect(() => {
    fetchRemoveHistoryListe();
  }, [dateSearch]);

  // Increment stock
  const [pID, setPID] = useState("");
  const [Qt, setQt] = useState("");

  const incrementStock = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      quantite: Qt,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/add/on/the/${pID}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        setPID("");
        setQt("");
        setModal6(false);
        setModal4(false);
        fetchAllProducts();
        fetchAddHistoryListe();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Decrement stock
  const [Qt1, setQt1] = useState("");

  const decrementStock = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      quantite: Qt1,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/remove/on/the/${pID}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        setPID("");
        setQt1("");
        setModal7(false);
        setModal4(false);
        fetchAllProducts();
        fetchRemoveHistoryListe();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Add Provider to Product
  const [ProviderID, setProviderID] = useState("");
  const [ProductID, setProductID] = useState("");
  const [ProviderPrice, setProviderPrice] = useState("");

  const addProviderToProduct = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      provider_id: ProviderID,
      product_id: ProductID,
      provider_price: ProviderPrice,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/add/provider/to/product`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        setProviderID("");
        setProductID("");
        setProviderPrice("");
        setModal5(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Get All Provider Add to Product
  const [providersForProduct, setProvidersForProduct] = useState([]);
  const [loading4, setLoading4] = useState(true);
  const [notFound4, setNotFound4] = useState(false);

  useEffect(() => {
    fetchPP();
  }, [pID]);

  const fetchPP = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/get/all/${pID}/providers?query`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setNotFound4(false);
        setLoading4(false);
        setProvidersForProduct(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setNotFound4(true);
          setLoading4(false);
        }
        console.log(error);
      });
  };

  // Get All Provider For Filter
  const [providersForP, setProvidersForP] = useState([]);
  const [provSearch, setProvSearch] = useState("");
  const [loading5, setLoading5] = useState(true);
  const [notFound5, setNotFound5] = useState(false);

  useEffect(() => {
    fetchAPF();
  }, [provSearch]);

  const fetchAPF = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/get/all/providers?query=${provSearch}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setNotFound5(false);
        setLoading5(false);
        setProvidersForP(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setNotFound5(true);
          setLoading5(false);
        }
        console.log(error);
      });
  };

  return (
    <>
      {/* Main */}
      <div className="py-[60px] w-full flex-col  max-sm:px-2">
        {/* Header */}
        <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
          <div className="flex items-center">
            {/* Add New */}
            <button
              onClick={() => setModal(true)}
              className="flex items-center bg-[#ffffff24] hover:p-3 hover:bg-blue-5 p-2 rounded-xl"
            >
              <h2 className="text-white">Ajout produit</h2>
              <MdOutlineExposurePlus1
                size={25}
                className="text-green-500 ml-2"
              />
            </button>

            {/* Add Provider */}
            <button
              onClick={() => setModal1(true)}
              className="flex items-center bg-[#ffffff24] ml-5 hover:p-3 hover:bg-blue-5 p-2 rounded-xl"
            >
              <h2 className="text-white">Ajout Fournisseur</h2>
              <CiDeliveryTruck size={25} className="text-yellow-500 ml-2" />
            </button>

            {/* Get History */}
            <button
              onClick={() => setModal2(true)}
              className="flex items-center bg-[#ffffff24] ml-5 hover:p-3 hover:bg-blue-5 p-2 rounded-xl"
            >
              <h2 className="text-white">Historique opération</h2>
              <MdOutlineHistoryToggleOff
                size={25}
                className="text-white ml-2"
              />
            </button>

            {/* Get all provider */}
            <button
              onClick={() => {
                setModal3(true);
                fetchAPF();
              }}
              className="flex items-center bg-[#ffffff24] ml-5 hover:p-3 hover:bg-blue-5 p-2 rounded-xl"
            >
              <h2 className="text-white">Liste fournisseur</h2>
              <RiBuilding2Line size={25} className="text-[#2109db] ml-2" />
            </button>

            {/* Get all Statistique */}
            <button
              onClick={() => {
                // setModal3(true);
                // fetchAllProviders();
              }}
              className="flex items-center bg-[#ffffff24] ml-5 hover:p-3 hover:bg-blue-5 p-2 rounded-xl"
            >
              <h2 className="text-white">Statistique</h2>
              <FaChartPie size={25} className="text-red-600 ml-2" />
            </button>
          </div>

          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Recherche de produit"
              className="border-none outline-none hover:animate-bounce bg-transparent w-full h-full text-white ml-2"
              value={searchP}
              onChange={(e) => setSearchP(e.target.value)}
            />
          </div>
        </div>

        {/* Stock liste*/}
        {loading ? (
          <div className="flex h-[auto] flex-col items-center mt-10 justify-center">
            <img src={Load} className="w-12 h-12" />
          </div>
        ) : notFound ? (
          <div className="flex flex-col items-center justify-center">
            <img src={Nothing} className="w-32 h-32" />
            <p className="text-white">Aucun produit trouvé !</p>
          </div>
        ) : (
          <div className="h-[90vh] grid grid-cols-5 overflow-auto scrollbar-hide">
            {[...product].map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setPID(item.id);
                    setProductID(item.id);
                    setPName(item.nom);
                    setQuantity(item.quantite);
                    setPrice(item.prix_unitaire_standard);
                    setDate(item.date);
                    fetchPP();
                    setModal4(true);
                  }}
                  key={index}
                  className="bg-[#ffffff24] hover:animate-pulse flex items-start justify-between hover:bg-slate-700 cursor-pointer m-2 p-2 rounded-xl shadow-md h-[150px]"
                >
                  {/* Left */}
                  <div className="flex flex-col justify-around w-[60%] h-full">
                    {/* Name */}
                    <p className="text-white font-bold text-lg">
                      {item.nom.length > 15
                        ? item.nom.slice(0, 15) + "..."
                        : item.nom}
                    </p>

                    {/* Quantity */}
                    <p className="text-yellow-600 font-bold">
                      Quantités : {item.quantite}
                    </p>

                    {/* Price */}
                    <p className="text-green-600 font-bold ">
                      Prix :{" "}
                      {(
                        parseInt(item.prix_unitaire_standard).toFixed(0) * 1
                      ).toLocaleString("fr-FR") +
                        " " +
                        "FCFA"}
                    </p>

                    {/* Date */}
                    <p className="text-blue-600 ">
                      Date : {moment(item.date).calendar()}
                    </p>
                  </div>

                  {/* Right */}
                  <div>
                    <img
                      src={Shop}
                      className="w-[70px] h-[70px] opacity-70 rotate-[20deg]"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* Create New Stock Modal */}
      <div
        className={
          modal
            ? "w-[83.5%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] rounded-xl absolute flex flex-col  top-40 px-5 w-[25%] h-[50vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <div className="flex items-center">
              <BsCartPlus size={25} color="white" className="mt-4" />

              <h2 className="text-white ml-2 text-center text-center text-xl mt-5 font-bold">
                Nouvel enregistrement
              </h2>
            </div>

            {/* Close Button */}
            <button
              onClick={() => setModal(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Body */}
          <div className="mt-10">
            {/* Nom */}
            <div className="bg-[#ffffff24] p-3">
              <input
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                type="text"
                placeholder="Nom"
                className="bg-[transparent] h-full outline-none text-white"
              />
            </div>

            {/* Quantite */}
            <div className="bg-[#ffffff24] p-3 my-6">
              <input
                maxLength={5}
                type="text"
                placeholder="Quantité"
                className="bg-[transparent] h-full outline-none text-white"
                value={quantite}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^\d.]/g, "");
                  setQuantite(filteredInput);
                }}
              />
            </div>

            {/* Prix Unitaire */}
            <div className="bg-[#ffffff24] p-3 flex items-center">
              <input
                maxLength={7}
                type="text"
                placeholder="Prix unitaire"
                className="bg-[transparent] h-full w-full outline-none text-white"
                value={prix_unitaire}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^\d.]/g, "");
                  setPrix_unitaire(filteredInput);
                }}
              />

              {/* Symbole */}
              <h2 className="text-white">FCFA</h2>
            </div>

            {/* Submite button */}
            <div className="px-10">
              <button
                disabled={
                  nom.length === 0 ||
                  quantite.length === 0 ||
                  prix_unitaire.length === 0
                }
                onClick={() => createNewProduct()}
                className={
                  nom.length === 0 ||
                  quantite.length === 0 ||
                  prix_unitaire.length === 0
                    ? "flex bg-[#050da334] justify-center w-full p-3 items-center mt-10 hover:bg-[#050da364] opacity-50"
                    : "flex bg-[#050da334] justify-center w-full p-3 items-center mt-10 hover:bg-[#050da364]"
                }
              >
                <LuPlus size={20} color="white" />
                <p className="ml-2 font-bold text-[#050da3]">Enregistré</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Create New Fournisseur Modal */}
      <div
        className={
          modal1
            ? "w-[83.5%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] rounded-xl absolute flex flex-col  top-40 px-5 w-[25%] h-[50vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <div className="flex items-center">
              <BsCartPlus size={25} color="white" className="mt-4" />

              <h2 className="text-white ml-2 text-center text-center text-xl mt-5 font-bold">
                Nouveau fournisseur
              </h2>
            </div>

            {/* Close Button */}
            <button
              onClick={() => setModal1(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Body */}
          <div className="mt-10">
            {/* Nom */}
            <div className="bg-[#ffffff24] p-3">
              <input
                type="text"
                placeholder="Nom"
                className="bg-[transparent] h-full outline-none text-white"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* Quartier */}
            <div className="bg-[#ffffff24] p-3 my-10">
              <input
                type="text"
                placeholder="Nom du quartier"
                className="bg-[transparent] h-full outline-none text-white"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>

            {/* Telephone */}
            <div className="bg-[#ffffff24] flex items-center p-3 ">
              {/* Code phone */}
              <h3 className="text-white">+ 228</h3>
              <input
                maxLength={8}
                type="text"
                placeholder="Téléphone"
                className="bg-[transparent] ml-2 h-full outline-none text-white"
                value={phone}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^\d.]/g, "");
                  setPhone(filteredInput);
                }}
              />
            </div>

            {/* Submite button */}
            <div className="px-10 flex">
              <button
                disabled={
                  name.length === 0 ||
                  location.length === 0 ||
                  phone.length === 0
                }
                onClick={() => {
                  createNewProvider();
                }}
                className={
                  name.length === 0 ||
                  location.length === 0 ||
                  phone.length === 0
                    ? "flex bg-[#050da334] justify-center w-full p-3 items-center mt-10 hover:bg-[#050da364] opacity-50"
                    : "flex bg-[#050da334] justify-center w-full p-3 items-center mt-10 hover:bg-[#050da364]"
                }
              >
                <LuPlus size={20} className="text-gray-400" />
                <p className="ml-2 font-bold text-[#050da3]">Ajouter</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Historique des operations Modal */}
      <div
        className={
          modal2
            ? "w-[83.5%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] rounded-xl absolute flex flex-col  top-[70px] px-5 w-[45%] h-[85vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <div className="flex items-center">
              <BsCartPlus size={25} color="white" className="mt-4" />

              <h2 className="text-white ml-2 text-center text-center text-xl mt-5 font-bold">
                Historique des opérations
              </h2>
            </div>

            {/* Close Button */}
            <button
              onClick={() => setModal2(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Navigation */}
          <div className="flex items-center justify-between mt-3">
            <div className="w-6/12 p-2 rounded-full flex felx-row items-center bg-slate-700">
              {[...historyMenu].map((menu, index) => (
                <button
                  onClick={() => setSelect(menu.id)}
                  key={index}
                  className={
                    menu.id === select
                      ? "bg-slate-500 p-2 rounded-full w-full text-white"
                      : "p-2 rounded-full w-full text-white"
                  }
                >
                  {menu.title}
                </button>
              ))}
            </div>

            {/* Searchbar */}
            <div
              title="Recherche"
              className="bg-[#ADADAD36] w-3/12 max-sm:w-[auto] p-2 flex items-center"
            >
              <input
                type="date"
                placeholder="date"
                className="border-none outline-none cursor-pointer bg-transparent w-full h-full text-white ml-2"
                value={dateSearch}
                onChange={(e) => setDateSearch(e.target.value)}
              />
            </div>
          </div>

          {/* Body */}
          {/* Add History */}
          {notFound2 && select === 0 ? (
            <div className="flex flex-col items-center justify-center">
              <img src={Nothing} className="w-32 h-32" />
              <p className="text-white">Aucun produit trouvé !</p>
            </div>
          ) : select === 0 ? (
            <div className="flex flex-col overflow-auto scrollbar-hide h-[95%]">
              {addHistories.map((item) => (
                <div
                  key={item.id}
                  className="flex items-center justify-between p-2 hover:p-3 border-b-[#E6E6E6] hover:bg-slate-700"
                >
                  <div>
                    <p className="text-gray-400 font-bold text-lg">
                      {item.nom}
                    </p>
                    <p className="ml-3 text-green-400">
                      Quantité : + {item.quantite}
                    </p>
                    <p className="ml-auto text-gray-400">
                      Prix :{" "}
                      {parseInt(item.prix_unitaire).toLocaleString("fr-FR")}{" "}
                      FCFA
                    </p>
                    <p className="ml-auto text-red-400">
                      Total : -{" "}
                      {parseInt(
                        item.prix_unitaire * item.quantite,
                      ).toLocaleString("fr-FR")}{" "}
                      FCFA
                    </p>
                  </div>
                  <p className="ml-3 text-gray-400">
                    {moment(item.date).calendar()}
                  </p>
                </div>
              ))}
            </div>
          ) : null}

          {/* Remove History */}
          {notFound3 && select === 1 ? (
            <div className="flex flex-col items-center justify-center">
              <img src={Nothing} className="w-32 h-32" />
              <p className="text-white">Aucun produit trouvé !</p>
            </div>
          ) : select === 1 ? (
            <div className="flex flex-col overflow-auto scrollbar-hide h-[95%]">
              {removeHistories.map((item) => (
                <div
                  key={item.id}
                  className="flex items-center justify-between p-2 hover:p-3 border-b-[#E6E6E6] hover:bg-slate-700"
                >
                  <div>
                    <p className="text-gray-400 font-bold text-lg">
                      {item.nom}
                    </p>
                    <p className="ml-3 text-red-400">
                      Quantité : - {item.quantite}
                    </p>
                    <p className="ml-auto text-gray-400">
                      Prix :{" "}
                      {parseInt(item.prix_unitaire).toLocaleString("fr-FR")}{" "}
                      FCFA
                    </p>
                    <p className="ml-auto text-green-400">
                      Total : +{" "}
                      {parseInt(
                        item.prix_unitaire * item.quantite,
                      ).toLocaleString("fr-FR")}{" "}
                      FCFA
                    </p>
                  </div>
                  <p className="ml-3 text-gray-400">
                    {moment(item.date).calendar()}
                  </p>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      {/* Liste des fournisseurs Modal */}
      <div
        className={
          modal3
            ? "w-[83.5%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] rounded-xl absolute flex flex-col  top-40 px-5 w-[60%] h-[80vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <BsCartPlus size={25} color="white" className="mt-4" />

                <h2 className="text-white ml-2 text-center text-center text-xl mt-5 font-bold">
                  Listes des fournisseurs
                </h2>
              </div>

              {/* Searchbar */}
              <div
                title="Recherche"
                className="bg-[#ADADAD36] w-4/12 mr-5 mt-3 max-sm:w-[auto] p-2 flex flex-row items-center"
              >
                <CiSearch size={25} color="white" />
                <input
                  type="search"
                  placeholder="Filtrage fournisseurs"
                  className="border-none outline-none hover:animate-bounce bg-transparent w-full h-full text-white ml-2"
                  value={provSearch}
                  onChange={(e) => setProvSearch(e.target.value)}
                />
              </div>
            </div>

            {/* Close Button */}
            <button
              onClick={() => setModal3(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Body */}
          {loading5 ? (
            <div className="flex h-[aauto] flex-col items-center mt-10 justify-center">
              <img src={Load} className="w-12 h-12" />
            </div>
          ) : notFound5 ? (
            <div className="text-white text-center mt-10">
              Aucun fournisseur trouvé !
            </div>
          ) : (
            <div className="grid grid-cols-3 overflow-auto scrollbar-hide h-[95%]">
              {[...providersForP].map((item, index) => {
                return (
                  <div
                    className="flex flex-col items-start h-[145px] m-2 p-2 rounded-xl shadow-md bg-[#ffffff14] hover:bg-slate-700 cursor-pointer"
                    key={item.id}
                  >
                    <img src={ProviderIco} className="w-10 h-8 ml-3" />
                    <div>
                      {/* Product Name */}
                      <h3 className="text-yellow-500 font-bold ml-3">
                        {item.product_name}
                      </h3>

                      {/* Title */}
                      <h3 className="text-red-500 font-bold ml-3">
                        {item.provider_name}
                      </h3>

                      {/* Phone */}
                      <h3 className="text-gray-400 font-bold ml-3">
                        +228 {item.provider_phone}
                      </h3>

                      {/* Prix de vente */}
                      <h3 className="text-green-500 ml-3">
                        Prix de vente :{" "}
                        {(
                          parseInt(item.provider_price).toFixed(0) * 1
                        ).toLocaleString() +
                          " " +
                          "FCFA"}
                      </h3>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* Product Detail Modal */}
      <div
        className={
          modal4
            ? "w-[83.5%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] rounded-xl absolute flex flex-col  top-[25px] px-5 w-[45%] h-[95vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <div className="flex items-center">
              <BsCartPlus size={25} color="white" className="mt-4" />

              <h2 className="text-white ml-2 text-center text-center text-xl mt-5 font-bold">
                Détails de
              </h2>

              <h2 className="text-blue-500 ml-2 text-center text-center text-xl mt-5 font-bold">
                {pName}
              </h2>
            </div>

            {/* Close Button */}
            <button
              onClick={() => setModal4(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Info */}
          <div>
            {/* Product Info */}
            <div>
              {/* Title */}
              <div className="flex items-center mt-2 ml-3">
                <CgInfo size={20} color="white" />
                <h2 className="text-white ml-2">Info</h2>
              </div>

              <div className="bg-slate-700 flex items-start justify-between m-2 p-2 rounded-xl shadow-md h-[150px]">
                {/* Left */}
                <div className="flex flex-col justify-around w-[60%] h-full">
                  {/* Name */}
                  <p className="text-white font-bold text-lg">{pName}</p>

                  {/* Quantity */}
                  <p className="text-yellow-600 font-bold">
                    Quantités : {quantity}
                  </p>

                  {/* Price */}
                  <p className="text-green-600 font-bold ">
                    Prix standard :{" "}
                    {(parseInt(price).toFixed(0) * 1).toLocaleString("fr-FR") +
                      " " +
                      "FCFA"}
                  </p>

                  {/* Date */}
                  <p className="text-blue-600 ">
                    Date : {moment(date).calendar()}
                  </p>
                </div>

                {/* Right */}
                <div className="flex flex-col items-end justify-between h-full">
                  <img
                    src={Shop}
                    className="w-[70px] h-[70px] opacity-70 rotate-[20deg]"
                  />

                  {/* Operation groupe Button */}
                  <div className="flex items-center w-[150px] justify-between">
                    {/* Add */}
                    <button
                      onClick={() => setModal6(true)}
                      className="w-[70px] h-[40px] hover:bg-blue-900 flex items-center justify-center text-white font-bold rounded-xl bg-blue-700"
                    >
                      +
                    </button>

                    {/* Reduce */}
                    <button
                      onClick={() => setModal7(true)}
                      className="w-[70px] h-[40px] hover:bg-red-900 flex items-center justify-center text-white font-bold rounded-xl bg-red-700"
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Provider Liste && Price */}
          </div>

          {/* Provider Liste */}
          <div>
            {/* Title */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <TbTruckDelivery size={20} color="white" />
                <h2 className="text-white text-lg ml-3 font-bold">
                  Liste des fournisseurs lié
                </h2>
              </div>

              {/* Add Provider Button */}
              <button
                onClick={() => {
                  setModal5(true);
                }}
                className="w-[120px] h-[auto] m-3 flex flex-col items-center justify-center hover:bg-[#ffffff14] text-xs border-dashed border-[1px] text-white rounded-xl border-gray-400"
              >
                <GoPlus size={35} color="white" />
                Ajout fournisseur
              </button>
            </div>

            {/* Body */}
            {loading4 ? (
              <div className="flex h-[aauto] flex-col items-center mt-10 justify-center">
                <img src={Load} className="w-12 h-12" />
              </div>
            ) : notFound4 ? (
              <div className="text-white text-center mt-10">
                Aucun fournisseur trouvé !
              </div>
            ) : (
              <div className="flex flex-col overflow-auto scrollbar-hide h-[95%]">
                {[...providersForProduct].map((item, index) => {
                  return (
                    <div
                      className="flex items-center m-2 p-2 rounded-xl shadow-md hover:bg-[#ffffff14] cursor-pointer"
                      key={item.id}
                    >
                      <TbTruckDelivery size={20} color="white" />
                      <div>
                        {/* Title */}
                        <h3 className="text-white font-bold ml-3">
                          {item.provider_name}
                        </h3>

                        {/* Phone */}
                        <h3 className="text-gray-400 font-bold ml-3">
                          +228 {item.provider_phone}
                        </h3>

                        {/* Prix de vente */}
                        <h3 className="text-green-500 ml-3">
                          Prix de vente :{" "}
                          {(
                            parseInt(item.provider_price).toFixed(0) * 1
                          ).toLocaleString() +
                            " " +
                            "FCFA"}
                        </h3>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add Provider to product Modal*/}
      <div
        className={
          modal5
            ? "w-[83.5%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] rounded-xl absolute top-20 px-5 w-[60%] h-[60vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <h2 className="text-white text-center text-center text-xl mt-5 font-bold">
              Liste des fournisseurs
            </h2>

            {/* Close Button */}
            <button
              onClick={() => {
                setModal5(false);
                fetchAllProviders();
              }}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Body */}
          <div>
            {loading1 ? (
              <div className="flex h-[aauto] flex-col items-center mt-10 justify-center">
                <img src={Load} className="w-12 h-12" />
              </div>
            ) : (
              <div className="grid grid-cols-3 overflow-auto scrollbar-hide h-[98%]">
                {[...providers].reverse().map((item, index) => {
                  const checkProvider = providersForProduct.find(
                    (tt) => tt.provider_id === item.id && tt.product_id === pID,
                  );
                  return (
                    <button
                      title={
                        checkProvider &&
                        "Ce fournisseur existe déjà pour ce produit !"
                      }
                      disabled={checkProvider}
                      key={index}
                      className={
                        ProviderID === item.id
                          ? "flex flex-col hover:animate-pulse items-start bg-[#050da324] h-[140px] cursor-pointer justify-between m-2 p-2 rounded-xl"
                          : checkProvider
                            ? "flex flex-col items-start bg-[#050da324] h-[100px] justify-between m-2 p-2 rounded-xl opacity-50"
                            : "flex flex-col hover:animate-pulse items-start bg-[#ffffff14] h-[100px] cursor-pointer justify-between m-2 p-2 rounded-xl hover:bg-[#050da324]"
                      }
                      onClick={() => {
                        setProviderID(item.id);
                      }}
                    >
                      {/* Top */}
                      <div className="flex w-full items-center justify-between">
                        <img src={ProviderIco} className="w-10 h-10" />
                        {ProviderID === item.id && (
                          <FaCheckDouble size={20} color="red" />
                        )}
                      </div>

                      {/* Center */}
                      <div>
                        <h4 className="text-white text-xl">
                          {item.name.length > 20
                            ? item.name.slice(0, 20) + "..."
                            : item.name}
                        </h4>
                        <p className="text-gray-400 text-sm">{item.location}</p>
                      </div>

                      <div>
                        {ProviderID === item.id && (
                          <div className="bg-[#ffffff24] rounded-md p-2">
                            <input
                              className="bg-[transparent] outline-none w-full text-white"
                              placeholder="Entrer le prix d'achat"
                              value={ProviderPrice}
                              maxLength={7}
                              onChange={(e) => {
                                const input = e.target.value;
                                const filteredInput = input.replace(
                                  /[^\d.]/g,
                                  "",
                                );
                                setProviderPrice(filteredInput);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </button>
                  );
                })}
              </div>
            )}
          </div>

          {/* Confirmation Button */}
          <div>
            <button
              disabled={
                ProviderID.length === 0 ||
                ProductID.length === 0 ||
                ProviderPrice.length === 0
              }
              onClick={() => {
                addProviderToProduct();
              }}
              className={
                ProviderID.length === 0 ||
                ProductID.length === 0 ||
                ProviderPrice.length === 0
                  ? "w-[250px] absolute bottom-3 h-[auto] m-3 p-2 flex items-center bg-blue-600 justify-center hover:bg-blue-800 text-base text-white rounded-xl opacity-60"
                  : "w-[250px] absolute bottom-3 h-[auto] m-3 p-2 flex items-center bg-blue-600 justify-center hover:bg-blue-800 text-base text-white rounded-xl"
              }
            >
              Ajouter ce fournisseur
              <RiCheckDoubleLine size={35} color="white" className="ml-3" />
            </button>
          </div>
        </div>
      </div>

      {/* Increment product Modal */}
      <div
        className={
          modal6
            ? "w-[83.5%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] rounded-xl absolute top-20 px-5 w-[35%] h-[20vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <h2 className="text-white text-center text-center text-xl mt-5 font-bold">
              Augmenter le stock de ce produit
            </h2>

            {/* Close Button */}
            <button
              onClick={() => setModal6(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Body */}
          <div className="p-3">
            <div className="bg-[#ffffff12] p-3">
              <input
                className="bg-[transparent] w-full outline-none text-white"
                type="text"
                placeholder="Entrer la quantité"
                value={Qt}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^\d.]/g, "");
                  setQt(filteredInput);
                }}
              />
            </div>
          </div>

          {/* Button  */}
          <div className="flex justify-center">
            <button
              disabled={Qt.length === 0}
              onClick={() => incrementStock()}
              className={
                Qt.length === 0
                  ? "w-[120px] h-[50px] m-3 flex items-center justify-center hover:bg-[#ffffff14] text-xs border-dashed border-[1px] text-white rounded-xl border-gray-400 opacity-60"
                  : "w-[120px] h-[50px] m-3 flex items-center justify-center hover:bg-[#ffffff14] text-xs border-dashed border-[1px] text-white rounded-xl border-gray-400"
              }
            >
              Augmenter
            </button>
          </div>
        </div>
      </div>

      {/* Decrement product Modal */}
      <div
        className={
          modal7
            ? "w-[83.5%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] rounded-xl absolute top-20 px-5 w-[35%] h-[20vh]">
          {/* Header */}
          <div>
            {/* Title */}
            <h2 className="text-white text-center text-center text-xl mt-5 font-bold">
              Livré ce produit
            </h2>

            {/* Close Button */}
            <button
              onClick={() => setModal7(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>
          </div>

          {/* Body */}
          <div className="p-3">
            <div className="bg-[#ffffff12] p-3">
              <input
                className="bg-[transparent] w-full outline-none text-white"
                type="text"
                placeholder="Entrer la quantité"
                value={Qt1}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^\d.]/g, "");
                  setQt1(filteredInput);
                }}
              />
            </div>
          </div>

          {/* Button  */}
          <div className="flex justify-center">
            <button
              disabled={Qt1.length === 0}
              onClick={() => decrementStock()}
              className={
                Qt1.length === 0
                  ? "w-[120px] h-[50px] m-3 flex items-center justify-center hover:bg-[#ffffff14] text-xs border-dashed border-[1px] text-white rounded-xl border-gray-400 opacity-60"
                  : "w-[120px] h-[50px] m-3 flex items-center justify-center hover:bg-[#ffffff14] text-xs border-dashed border-[1px] text-white rounded-xl border-gray-400"
              }
            >
              Livré
            </button>
          </div>
        </div>
      </div>

      {/* Container */}
      <ToastContainer />
    </>
  );
}
