import React, { useState } from "react";
import LastCotisation from "./cpn/LastCotisations";
// import CarnetNotFinish from "./cpn/CarnetNotFinish";
// import NewClient from "./cpn/NewClient";

const clManagerMenu = [
  { id: 0, name: "Dernière cotisation" },
  { id: 1, name: "Carnets non terminé" },
  { id: 2, name: "Nouveau clients" },
];

const ClientManager = () => {
  const [select, setSelect] = useState(0);
  return (
    <div>
      {/* Menu */}
      <div className="mt-20">
        <ul className="flex flex-row gap-4">
          {clManagerMenu.map((item, index) => (
            <li
              key={index}
              className={`cursor-pointer justify-center items-center flex
                ${select === index ? "text-white bg-blue-500 p-3 rounded-full" : "text-white p-3 bg-[#aaaaa24]"}`}
              onClick={() => setSelect(index)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Render */}
      {select === 0 && <LastCotisation />}
      {/* {select === 1 && <CarnetNotFinish />}
      {select === 2 && <NewClient />} */}
    </div>
  );
};

export default ClientManager;
