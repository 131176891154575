import React from "react";
import Products from "../../comonents/magasin/ProductsListe";

export default function MagasinLgg() {
  return (
    <div className="overflow-hidden h-screen">
      <Products />
    </div>
  );
}
