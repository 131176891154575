import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Loading from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import Config from "../../config/Links";
import { TiUserOutline } from "react-icons/ti";
import { CiSearch } from "react-icons/ci";
import moment from "moment";
import "moment/locale/fr";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

function ColecteurListes() {
  const navigation = useNavigate();
  moment.locale("fr");
  const [collector, setCollector] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const url = Config.GESCAPO_LINK;
  const token = sessionStorage.getItem("gestoken");
  const pagePlus = () => setPage((prev) => prev + 1);
  const pageMoin = () => setPage((prev) => prev - 1);

  // Request
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/api/auth/admin/agent_collecteur/list?search=${search}&all=true`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchControleur = async () => {
      try {
        const response = await axios.request({
          ...config,
          url: `${url}/api/auth/admin/agent_collecteur/list?search=${search}&all=true`,
        });
        setCollector(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchControleur();
  }, [search]);

  const lists = useMemo(() => {
    return (
      <div className="flex flex-row flex-wrap max-h-[85vh] overflow-auto scrollbar-hide">
        {loading ? (
          <div className="w-full h-[85vh] max-sm:mb-[30%] flex flex-grow justify-center items-center">
            <img src={Loading} className="w-20 h-20" />
          </div>
        ) : collector.length > 0 ? (
          collector.map(
            ({ id, numero, nom, prenom, agc_name, nbr_cli, created_at }) => (
              <Link
                to={`/dashboard/colecteur/info/${id}`}
                title="Controleur"
                key={id}
                className="bg-slate-700 w-[295px] max-sm:w-full my-2 h-[150px] rounded-md justify-between px-2 mx-2 flex flex-row hover:bg-[#282828] cursor-pointer"
              >
                <div>
                  <TiUserOutline size={80} color="#fff" />
                </div>

                <div className="flex flex-col w-4/5 justify-around">
                  <p className="text-white">
                    {nom}{" "}
                    {prenom.length > 10 ? `${prenom.slice(0, 10)}...` : prenom}
                  </p>
                  <p className="text-white"> (+228) {numero}</p>
                  <p className="text-white">{agc_name}</p>
                  <p className="text-white">Nbre Client : {nbr_cli}</p>
                  <p className="text-white">
                    {" "}
                    Inscrit : {moment(created_at).calendar()}
                  </p>
                </div>
              </Link>
            ),
          )
        ) : (
          <div className="flex w-full justify-center items-center h-[60vh]">
            <div className="flex flex-col justify-center items-center mb-[10%] max-sm:mb-[50%]">
              <img src={Nothing} className="w-20 h-20 mb-4" />
              <p className="font-bold text-white mb-5">
                Aucun Collecteur trouver !
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }, [collector, search]);

  return (
    <div className="py-[60px] w-full flex-col  max-sm:px-2">
      {/* Search Bar */}
      <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
        {/* Back */}
        <div className="flex flex-row items-center">
          <button
            onClick={() => navigation(-1)}
            className="bg-[#ADADAD36] p-3 rounded-md hover:bg-slate-500"
          >
            <IoArrowBack color="white" />
          </button>

          <p className="font-bold text-2xl text-white ml-2">
            Listes des Collecteurs
          </p>
        </div>

        <div
          title="Recherche"
          className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
        >
          <CiSearch size={25} color="white" />
          <input
            type="search"
            placeholder="Recheche collecteur"
            className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      {lists}
    </div>
  );
}

export default ColecteurListes;
