import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import Task from "../../assets/task.png";
import Config from "../../config/Links";
import { FaUserPlus } from "react-icons/fa6";
import { FaTasks } from "react-icons/fa";
import { ImBin } from "react-icons/im";
import { CiEdit } from "react-icons/ci";
import { CiSearch } from "react-icons/ci";
import moment from "moment";
import "moment/locale/fr";
import { Link } from "react-router-dom";

function TasksArchive() {
  moment.locale("fr");
  const [task, setTask] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const url = Config.G_PERFORM_LINK;
  const token = sessionStorage.getItem("admin_tk");

  // Request
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/get/all/archive/tasks?query=${search}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.request(config);
        setTask(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchTasks();
  }, [search]);

  return (
    <div className="py-[60px] w-full flex-col  max-sm:px-2">
      {/* Search Bar */}
      <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
        {/* Add NEW Tasks */}
        <div className="flex flex-row w-[400px] justify-between items-center">
          {/* Create Task */}
          <Link to="/dashboard/create/task">
            <button
              title="Ajouter"
              className=" bg-[#ADADAD36] max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-2 max-sm:p-1 flex flex-row"
            >
              <FaTasks size={20} color="white" />
              <p className="text-white ml-2 text-xs">Ajouter une tâche</p>
            </button>
          </Link>

          <div>
            <p className="text-white font-bold text-xl">
              Liste des Tâches archiver
            </p>
          </div>
        </div>

        {/* Searchbar */}
        <div
          title="Recherche"
          className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
        >
          <CiSearch size={25} color="white" />
          <input
            type="search"
            placeholder="Filtrée vos tâches"
            className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <div className="flex flex-row flex-wrap max-h-[85vh] pb-[10%] overflow-auto scrollbar-hide">
        {loading ? (
          <div className="w-full h-[85vh] mb-[70%] max-sm:mb-[50%] flex flex-grow justify-center items-center">
            <img src={Loading} className="w-20 h-20" />
          </div>
        ) : task.length > 0 ? (
          task.map(
            ({
              id,
              title,
              description,
              start_date,
              end_date,
              status,
              created_date,
            }) => (
              <div
                title="Tâches"
                key={id}
                className="bg-slate-700 w-[320px] max-sm:w-full my-2 h-[160px] rounded-md justify-between px-2 mx-2 flex flex-row hover:bg-[#282828] cursor-pointer"
              >
                <div className="p-2 w-20 h-20">
                  <img src={Task} className="w-10 h-10" />
                </div>

                <div className="flex flex-col justify-around">
                  <p className="text-white font-bold text-ld">
                    {title.length > 20 ? `${title.slice(0, 20)}...` : title}
                  </p>
                  <p className="text-white flex-wrap">Tâches : {description}</p>
                  <p className="text-white">
                    Fin : {moment(end_date).calendar()}
                  </p>

                  <div className="flex flex-row items-center w-full">
                    <p className="text-white">Status : </p>
                    <p
                      className={
                        status === "En cours..."
                          ? "text-green-600"
                          : "text-red-600"
                      }
                    >
                      {status}
                    </p>
                  </div>

                  <p className="text-white">
                    Début : {moment(start_date).calendar()}
                  </p>
                </div>

                {/* Tools */}
                <div className="flex flex-col justify-between py-2"></div>
              </div>
            ),
          )
        ) : (
          <div className="flex w-full justify-center items-center h-[85vh]">
            <div className="flex flex-col justify-center items-center mb-[10%] max-sm:mb-[50%]">
              <img src={Nothing} className="w-20 h-20 mb-4" />
              <p className="font-bold text-white mb-5">Aucun tâche trouver !</p>

              {/* Add NEW Controleur */}
              <Link to="/dashboard/add/controleur">
                <button className="bg-[#ADADAD36] hover:text-slate-700 rounded-full items-center p-2 flex flex-row">
                  <FaUserPlus size={20} color="white" />
                  <p className="text-white ml-2">Ajouter une tâche</p>
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TasksArchive;
