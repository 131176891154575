import React from "react";
import TasksList from "../../components/tasks/TasksList";

function Tasks() {
  return (
    <div className="px-2 h-screen">
      <TasksList />
    </div>
  );
}

export default Tasks;
