import React from "react";
import Agence from "../../comonents/agences/AgencesListeLgg";

export default function AgencesLgg() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <Agence />
    </div>
  );
}
