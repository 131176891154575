import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Loading from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import Desiste from "../../assets/desist.png";
import Config from "../../config/Links";
import { CiSearch } from "react-icons/ci";
import moment from "moment";
import "moment/locale/fr";

function DesistementList() {
  moment.locale("fr");
  const [desistement, setDesistement] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const url = Config.G_PERFORM_LINK;
  const token = sessionStorage.getItem("admin_tk");

  // Request
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/get/desistement/list`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchDesistenent = async () => {
      try {
        const response = await axios.request(config);
        setDesistement(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchDesistenent();
  }, []);

  const lists = useMemo(() => {
    return (
      <div className="flex flex-row flex-wrap max-h-[90vh] pb-[10%] overflow-auto scrollbar-hide">
        {loading ? (
          <div className="w-full h-[85vh] mb-[70%] max-sm:mb-[50%] flex flex-grow justify-center items-center">
            <img src={Loading} className="w-20 h-20" />
          </div>
        ) : desistement?.length > 0 ? (
          desistement?.map(
            ({
              id,
              client_nom,
              client_prenom,
              total_amout,
              total_product,
              fee,
              create_date,
            }) => (
              <div
                title="Desistement"
                key={id}
                className="bg-slate-800 w-[310px] max-sm:w-full my-2 h-[150px] rounded-md justify-between px-2 mx-2 flex flex-row hover:bg-[#282828] cursor-pointer"
              >
                <div className="p-1 bg-slate-50 rounded-full flex w-10 h-10 justify-center items-center">
                  <img src={Desiste} className="w-8 h-7" />
                </div>

                <div className="flex flex-col justify-around">
                  <p className="text-white font-bold text-lg">{client_nom}</p>
                  <p className="text-white">{client_prenom}</p>
                  <p className="text-white">
                    {" "}
                    Montants : {total_amout.toLocaleString("FR-fr")} FCFA
                  </p>
                  <p className="text-white"> Produit : {total_product}</p>
                  <p className="text-white">
                    {" "}
                    Frais : {fee.toLocaleString("FR-fr")} FCFA
                  </p>
                  <p className="text-white">
                    Crée : {moment(create_date).calendar()}
                  </p>
                </div>

                {/* Tools */}
                <div className="flex flex-col justify-between py-2"></div>
              </div>
            ),
          )
        ) : (
          <div className="flex w-full justify-center items-center h-[85vh]">
            <div className="flex flex-col justify-center items-center mb-[10%] max-sm:mb-[50%]">
              <img src={Nothing} className="w-20 h-20 mb-4" />
              <p className="font-bold text-white mb-5">
                Aucun élement trouver !
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }, [desistement]);

  return (
    <div className="py-[60px] w-full flex flex-col max-sm:pb-[45%] max-sm:px-2">
      {/* Search Bar */}
      <div className="w-full mb-2 flex flex-row items-center justify-end px-3 max-sm:px-0">
        {/* Searchbar */}
        <div
          title="Recherche"
          className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
        >
          <CiSearch size={25} color="white" />
          <input
            type="search"
            placeholder="Trouver les desistements"
            className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      {lists}
    </div>
  );
}

export default DesistementList;
