import React from "react";
import ControleurListes from "../../components/controleur/controleurListes";

function Controleur() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <ControleurListes />
    </div>
  );
}

export default Controleur;
