import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import { TbUserHexagon } from "react-icons/tb";
import Config from "../../config/Links";
import Refresh from "../../assets/rff.svg";
import { FaUserPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { GoDotFill } from "react-icons/go";
import { IoIosRefresh } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineDisconnect } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import { PiBuildingOffice } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";
import "react-toastify/dist/ReactToastify.css";
import qs from "qs";
import moment from "moment";
import "moment/locale/fr";
import { Link } from "react-router-dom";
import { IoIosLock } from "react-icons/io";
import { ImBin } from "react-icons/im";

const url = Config.G_PERFORM_LINK;
const gesUrl = Config.GESCAPO_LINK;
const token = sessionStorage.getItem("admin_tk");
const token1 = sessionStorage.getItem("gestoken");

function ControleurListes() {
  moment.locale("fr");
  const [controlor, setControlor] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [controleurID, setControleurID] = useState();
  const [showModal1, setShowModal1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [open, setOpen] = useState(false);
  const [agence, setAgence] = useState([]);

  const [nom, setNom] = useState("");
  const [numero_de_telephone, setNumero_de_telephone] = useState("");
  const [agenceId, setAgenceId] = useState(null);
  const [agence_name, setAgence_name] = useState("");

  const [select, setSelect] = useState(0);
  const [selectName, setSelectName] = useState("");

  // Generate
  const avl = "checker@";
  const makeid = (length) => {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return avl + result;
  };

  // Request
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/search/controleur?query=${search}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchControleur = async () => {
      try {
        const response = await axios.request(config);
        setControlor(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchControleur();
  }, [search]);

  const fetchControleur = async () => {
    try {
      const response = await axios.request(config);
      setControlor(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchControleur();
  }, []);

  const setNewPass = async () => {
    if (!controleurID) {
      console.error("ID du contrôleur non défini.");
      return;
    }

    try {
      const data = qs.stringify({
        newPasse: makeid(4),
      });

      const config = {
        method: "patch",
        url: `${url}/v1/admin/generate/controlleur/new/passe/${controleurID}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      const response = await axios(config);

      if (response.status === 200) {
        toast.success("Mot de passe modifié avec succès");
        fetchControleur();
      }
    } catch (error) {
      console.error("Erreur lors de la modification du mot de passe :", error);
      toast.error(
        error.response.data.message ||
          "Erreur lors de la modification du mot de passe",
      );
    }
  };

  useEffect(() => {
    setNewPass();
  }, []);

  // Edit controleur info
  // FETCH ALL AGENCE
  let config5 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${gesUrl}/api/auth/admin/agence/list`,
    headers: {
      Authorization: `Bearer ${token1}`,
    },
  };

  useEffect(() => {
    const fetchAgenceListe = async () => {
      await axios
        .request(config5)
        .then((response) => {
          setAgence(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAgenceListe();
  }, []);

  // Eite Controleur
  let data = qs.stringify({
    nom: nom,
    numero_de_telephone: numero_de_telephone,
    agence: agenceId,
    agence_name: agence_name,
  });

  let config4 = {
    method: "patch",
    maxBodyLength: Infinity,
    url: `${url}/controleur/edit/${controleurID}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  const editControleur = async () => {
    await axios
      .request(config4)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setShowModal1(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        setShowModal1(false);
      });
  };

  // Delete Controleur
  let config3 = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${url}/controleur/delete/${controleurID}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const deleteControleur = async () => {
    await axios
      .request(config3)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Contrôleur supprimé avec succès");
          setShowModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erreur de suppression du contrôleur !");
        setShowModal(false);
      });
  };

  // Disconnect Controleur
  let config8 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/disconnect/cotroleur/${controleurID}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const discoonnectIt = async () => {
    await axios
      .request(config8)
      .then((response) => {
        toast.success(response.data.message);
        setShowModal3(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        setShowModal3(false);
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  return (
    <>
      <div className="py-[60px] w-full flex-col  max-sm:px-2">
        {/* Search Bar */}
        <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
          {/* Add NEW Controleur */}
          <Link to="/dashboard/add/controleur">
            <button
              title="Ajouter"
              className=" bg-[#ADADAD36] max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-2 max-sm:p-1 flex flex-row"
            >
              <FaUserPlus size={20} color="white" />
              <p className="text-white ml-2 text-xs">Ajouter un controleur</p>
            </button>
          </Link>

          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Recheche contrôleur"
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-row flex-wrap max-h-[85vh] overflow-auto scrollbar-hide">
          {loading ? (
            <div className="w-full h-[90vh] mb-[30%] max-sm:mb-[30%] flex flex-grow justify-center items-center">
              <img src={Loading} className="w-20 h-20" />
            </div>
          ) : controlor.length > 0 ? (
            controlor.map(
              ({
                id,
                nom,
                numero_telephone,
                visible_passe,
                agence,
                agence_name,
                is_connect,
                code_secret,
                date_creation,
              }) => (
                <div
                  title="Controleur"
                  key={id}
                  className="bg-slate-700 w-[295px] max-sm:w-full my-2 h-[200px] rounded-md justify-between px-2 mx-2 flex flex-row hover:bg-[#282828] cursor-pointer"
                >
                  <div className="flex flex-col justify-between">
                    <TbUserHexagon size={50} color="#fff" />

                    <button
                      onClick={() => {
                        setControleurID(id);
                        setShowModal3(!showModal3);
                      }}
                      disabled={is_connect === 0}
                      className={
                        is_connect === 0
                          ? "bg-[#ADADAD36] p-1 rounded-full w-[45px] h-[45px] flex items-center justify-center cursor-pointer opacity-30"
                          : "bg-[#ADADAD36] p-1 rounded-full w-[45px] h-[45px] flex items-center justify-center cursor-pointer"
                      }
                      title="Déconnecter"
                    >
                      <AiOutlineDisconnect size={30} color="#fff" />
                    </button>
                  </div>

                  <div className="flex flex-col justify-around">
                    {/* Name */}
                    <p className="text-white">
                      {nom.length > 20 ? `${nom.slice(0, 20)}...` : nom}
                    </p>
                    {/* Pass */}
                    <div className="flex flex-row items-center">
                      <IoIosLock color="white" />
                      <p className="text-red-500"> {visible_passe}</p>
                    </div>

                    {/* Secret */}
                    <div className="flex flex-row items-center">
                      <IoIosLock color="white" />{" "}
                      <p className="text-red-500"> Secret : {code_secret}</p>
                    </div>

                    <p className="text-white"> (+228) {numero_telephone}</p>
                    <p className="text-white">{agence_name}</p>
                    {/* Is Connected */}
                    <div className="flex felx-col items-center">
                      {/* Ico */}
                      {is_connect === 0 ? (
                        <GoDotFill className="text-red-600" size={25} />
                      ) : (
                        <GoDotFill className="text-green-600" size={25} />
                      )}
                      {/* Text */}
                      <p
                        className={
                          is_connect === 0 ? "text-red-600" : "text-green-600"
                        }
                      >
                        {is_connect === 0 ? "Déconnecté" : "Connecté"}
                      </p>
                    </div>

                    <p className="text-white">
                      {moment(date_creation).calendar()}
                    </p>
                  </div>

                  {/* Tools */}
                  <div className="flex flex-col justify-between py-2">
                    <button
                      onClick={() => {
                        setShowModal1(true);
                        setControleurID(id);
                        setNom(nom);
                        setNumero_de_telephone(numero_telephone);
                        setAgenceId(agence);
                        setAgence_name(agence_name);
                      }}
                      title="Editer"
                    >
                      <CiEdit size={20} color="white" />
                    </button>

                    <button
                      onClick={() => {
                        setControleurID(id);
                        setNewPass();
                      }}
                      className="hover:bg-slate-400 p-2 rounded-full"
                    >
                      <IoIosRefresh color="#fff" />
                    </button>

                    <button
                      onClick={() => {
                        setShowModal(true);
                        setControleurID(id);
                      }}
                      title="Supprimer"
                    >
                      <ImBin size={20} color="red" />
                    </button>
                  </div>
                </div>
              ),
            )
          ) : (
            <div className="flex w-full justify-center items-center h-[85vh]">
              <div className="flex flex-col justify-center items-center mb-[10%] max-sm:mb-[50%]">
                <img src={Nothing} className="w-20 h-20 mb-4" />
                <p className="font-bold text-white mb-5">
                  Aucun Contrôleur trouver !
                </p>

                {/* Add NEW Controleur */}
                <Link to="/dashboard/add/controleur">
                  <button className="bg-[#ADADAD36] hover:text-slate-700 rounded-full items-center p-2 flex flex-row">
                    <FaUserPlus size={20} color="white" />
                    <p className="text-white ml-2">Ajouter un controleur</p>
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Edite POP */}
      <div
        className={
          !showModal1
            ? "hidden"
            : "flex w-full bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal1
              ? "hidden"
              : "absolute w-[340px] h-[350px] flex flex-col max-sm:w-full px-5 rounded-xl justify-between bg-gray-900 top-[25%]"
          }
        >
          {/* Title */}
          <div className="mb-[50px] flex flex-row justify-center mt-2">
            <p className="text-white font-bold text-xl text-center">
              Modifier le contrôleur
            </p>

            {/* Close */}
            <button
              onClick={() => setShowModal1(false)}
              className="p-2 bg-[#ADADAD36] absolute right-2 rounded-full"
            >
              <MdOutlineClose color="white" />
            </button>
          </div>

          {/* Data */}
          <div className="h-full">
            {/* Edit Name */}
            <div>
              <input
                type="text"
                className="text-black w-full p-2"
                defaultValue={nom}
                onChange={(e) => setNom(e.target.value)}
              />

              {/* Phone */}
              <input
                type="text"
                className="text-black my-5 w-full p-2"
                maxLength={8}
                defaultValue={numero_de_telephone}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^\d.]/g, "");
                  setNumero_de_telephone(filteredInput);
                }}
              />

              {/* Edite Agency */}
              <button
                onClick={() => setOpen(!open)}
                className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4 w-full justify-between"
              >
                <div className="flex justify-between items-center">
                  <PiBuildingOffice size={30} color="#fff" />
                  <p className="text-white ml-3">{selectName || agence_name}</p>
                </div>

                <IoIosArrowDown size={20} color="#fff" />
              </button>
            </div>

            {/* Agency List */}
            <div className="flex justify-center">
              <div
                className={
                  !open
                    ? "hidden transition-all"
                    : "absolute w-[250px] flex flex-col justify-center  items-center bg-black max-sm:bg-slate-500 rounded-xl max-h-[250px] max-sm:h-[200px] mb-9 max-sm:mb-20 top-[180px] z-10 transition"
                }
              >
                <div className="flex flex-row items-center h-[30px]">
                  <PiBuildingOffice size={20} color="#fff" className="mt-2" />
                  <p className="text-white text-lg font-semibold ml-3 mt-2">
                    List des Agences
                  </p>
                </div>

                {/* Divider */}
                <div className="w-full h-[1px] bg-slate-100 my-2" />

                {/* Body */}
                <div className="overflow-auto scrollbar-hide flex flex-col">
                  {loading ? (
                    <div className="w-full h-full flex flex-col justify-center items-center p-3">
                      <img src={Refresh} className="w-12 h-12" />
                      <p className="text-white">Recherche Agence...</p>
                    </div>
                  ) : (
                    agence?.length > 0 &&
                    agence &&
                    agence?.map(({ id, nom_agence }) => (
                      <button
                        key={id}
                        onClick={() => {
                          setSelect(id);
                          setSelectName(nom_agence);
                          setAgenceId(id);
                          setAgence_name(nom_agence);
                          setOpen(false);
                        }}
                        className={
                          select === id
                            ? "bg-slate-700 p-2"
                            : "hover:bg-slate-700 p-2"
                        }
                      >
                        <p className="text-white">{nom_agence}</p>
                      </button>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Save Button */}
          <div className="mb-7 flex items-center justify-center">
            {/* Save */}
            <button
              onClick={() => editControleur()}
              className="w-full h-10 bg-blue-600 rounded-md text-center hover:bg-slate-600"
            >
              <p className="text-white font-medium text-base">Sauvegarder</p>
            </button>
          </div>
        </div>
      </div>

      {/* Disconnecte Controleur */}
      <div
        className={
          !showModal3
            ? "hidden"
            : "flex w-full bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal3
              ? "hidden"
              : "absolute w-[340px] h-[240px] flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#F1000029]">
              <AiOutlineDisconnect size={30} color="red" />
            </div>
          </div>

          {/* Title */}
          <div className="mb-[50px]">
            <p className="text-white font-bold text-xl text-center">
              Déconnecter ce contrôleur ?
            </p>
          </div>

          <div className="px-[10%] w-full absolute flex justify-between bottom-5">
            {/* yes  */}
            <button
              onClick={() => discoonnectIt()}
              className="w-full h-10 bg-red-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Oui</p>
            </button>

            {/* Not  */}
            <button
              onClick={() => {
                setShowModal3(false);
              }}
              className="w-full h-10 bg-blue-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Non</p>
            </button>
          </div>
        </div>
      </div>

      {/* Delete Controleur */}
      <div
        className={
          !showModal
            ? "hidden"
            : "flex w-full bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal
              ? "hidden"
              : "absolute w-[340px] h-[240px] flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#F1000029]">
              <ImBin size={30} color="red" />
            </div>
          </div>

          {/* Title */}
          <div className="mb-[50px]">
            <p className="text-white font-bold text-xl text-center">
              Supprimer ce contrôleur ?
            </p>
          </div>

          <div className="px-[10%] w-full absolute flex justify-between bottom-5">
            {/* yes  */}
            <button
              onClick={() => deleteControleur()}
              className="w-full h-10 bg-red-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Oui</p>
            </button>

            {/* Not  */}
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className="w-full h-10 bg-blue-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Non</p>
            </button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default ControleurListes;
