import React, { useEffect, useState } from "react";
import { FaRegBuilding } from "react-icons/fa";
import { LuMapPin } from "react-icons/lu";
import { MdOutlinePhone } from "react-icons/md";
import { VscPercentage } from "react-icons/vsc";
import { CiCirclePlus } from "react-icons/ci";
import { FaPenAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { RiUser3Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineDashboard } from "react-icons/ai";
import { PiBuildingOffice } from "react-icons/pi";
import { LuUser2 } from "react-icons/lu";
import { PiCardsThreeLight } from "react-icons/pi";
import { CiSettings } from "react-icons/ci";
import { LiaTasksSolid } from "react-icons/lia";
import { TbUsers } from "react-icons/tb";
import { MdOutlineShoppingCart } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { PiUserPlusLight } from "react-icons/pi";
import Switch from "react-switch";
import { SlRefresh } from "react-icons/sl";
import { TbCreditCardOff } from "react-icons/tb";
import { IoIosArrowDown } from "react-icons/io";
import { GrUserSettings } from "react-icons/gr";
import { FaPencil } from "react-icons/fa6";
import Refresh from "../../assets/rff.svg";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";
import Config from "../../config/Links";
import moment from "moment";
import "moment/locale/fr";

const url = Config.G_PERFORM_LINK;
const gesUrl = Config.GESCAPO_LINK;
const token = sessionStorage.getItem("admin_tk");
const token1 = sessionStorage.getItem("gestoken");
const super_token = sessionStorage.getItem("super_token");

function Seettings() {
  // Generate Passe
  const avl = "gperf@";
  const makeid = (length) => {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return avl + result;
  };

  console.log(makeid(5));

  moment.locale("fr");
  const [phone, setPhone] = useState([]);
  const [compInfo, setCompInfo] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);

  // Admin list
  const [adminList, setAminList] = useState([]);

  // Super Admin Login
  const [numero_telephone, setNumero_telephone] = useState("");
  const [mot_de_passe, setMot_de_passe] = useState("");

  // Admin Signup
  const [sNom, setSNom] = useState("");
  const [sNumero_telephone, setSNumero_telephone] = useState("");
  const [sMot_de_passe, setSMot_de_passe] = useState(makeid(5));

  // Agency list

  // Edit info
  const [agcId, setAgcId] = useState("");
  const [agc, setAgc] = useState("");
  const [agcNumber, setAgcNumber] = useState("");
  const [pop, setPop] = useState(false);
  const [popX, setPopX] = useState(false);
  const [popY, setPopY] = useState(false);
  const [pop1, setPop1] = useState(false);
  const [pop2, setPop2] = useState(false);
  const [pop3, setPop3] = useState(false);
  const [pop4, setPop4] = useState(false);
  const [pop5, setPop5] = useState(false);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone1, setPhone1] = useState("");
  const [payment_pourcent, setPayment_pourcent] = useState("");
  const [epargne_pourcent, setEpargne_pourcent] = useState("");

  // Add Agency Number
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [agence, setAgence] = useState([]);
  const [select, setSelect] = useState(0);
  const [selectName, setSelectName] = useState("");
  const [adminID1, setAdminID1] = useState("");
  const [getAdminSetting1, setGetAdminSettings1] = useState({});

  // AGC
  const [aggId, setAggId] = useState(null);
  const [nber, setNber] = useState("");

  useEffect(() => {
    setAgcId();
  }, [agcId]);

  // Fetch Company Info
  let config3 = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/get/company/info`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchComp = async () => {
      await axios
        .request(config3)
        .then((response) => {
          setCompInfo(response.data.companyInfo);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchComp();
  }, []);

  // FETCH ALL AGENCE
  let config1 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${gesUrl}/api/auth/admin/agence/list`,
    headers: {
      Authorization: `Bearer ${token1}`,
    },
  };

  useEffect(() => {
    const fetchAgenceListe = async () => {
      await axios
        .request(config1)
        .then((response) => {
          setAgence(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAgenceListe();
  }, []);

  // Fetch All AGENCE && PHONE
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/get/phonenumber`,
    headers: {},
  };

  useEffect(() => {
    const fetchComp = async () => {
      await axios
        .request(config)
        .then((response) => {
          setPhone(response.data.phoneNumbers);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchComp();
  }, [aggId]);

  // Add New Number
  let data1 = qs.stringify({
    agence_id: agcId,
    agence_name: agc,
    newPhone: agcNumber,
  });

  let config4 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/add/new/phonenumber`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };

  const fetchComp = async () => {
    await axios
      .request(config)
      .then((response) => {
        setPhone(response.data.phoneNumbers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addNewNumber = async () => {
    await axios
      .request(config4)
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          fetchComp();
          setPop(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        setPop(false);
      });
  };

  // Edite Company INFO Name
  const handleSubmitName = async () => {
    try {
      let data = qs.stringify({
        name: name,
        address: compInfo.address,
        phone: compInfo.phone,
        payment_pourcent: compInfo.payment_pourcent,
        epargne_pourcent: compInfo.epargne_pourcent,
      });

      const config1 = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${url}/v1/admin/company/info`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      const response = await axios(config1);

      if (response.status === 200) {
        toast.success("Modifications enregistrées avec succès");
        setPop1(!pop1);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Erreur lors de la modification des informations de l'entreprise",
      );
    }
  };

  // Edite Company INFO Name
  const handleSubmitAdress = async () => {
    try {
      let data = qs.stringify({
        name: compInfo.name,
        address: address,
        phone: compInfo.phone,
        payment_pourcent: compInfo.payment_pourcent,
        epargne_pourcent: compInfo.epargne_pourcent,
      });

      const config1 = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${url}/v1/admin/company/info`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      const response = await axios(config1);

      if (response.status === 200) {
        toast.success("Modifications enregistrées avec succès");
        setPop1(!pop2);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Erreur lors de la modification des informations de l'entreprise",
      );
    }
  };

  // Edite Company INFO Name
  const handleSubmitPhone = async () => {
    try {
      let data = qs.stringify({
        name: compInfo.name,
        address: compInfo.address,
        phone: phone1,
        payment_pourcent: compInfo.payment_pourcent,
        epargne_pourcent: compInfo.epargne_pourcent,
      });

      const config1 = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${url}/v1/admin/company/info`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      const response = await axios(config1);

      if (response.status === 200) {
        toast.success("Modifications enregistrées avec succès");
        setPop1(!pop3);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Erreur lors de la modification des informations de l'entreprise",
      );
    }
  };

  // Edite Company INFO Name
  const handleSubmitProductPourcent = async () => {
    try {
      let data = qs.stringify({
        name: compInfo.name,
        address: compInfo.address,
        phone: compInfo.phone,
        payment_pourcent: payment_pourcent,
        epargne_pourcent: compInfo.epargne_pourcent,
      });

      const config1 = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${url}/v1/admin/company/info`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      const response = await axios(config1);

      if (response.status === 200) {
        toast.success("Modifications enregistrées avec succès");
        setPop1(!pop4);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Erreur lors de la modification des informations de l'entreprise",
      );
    }
  };

  // Edite Company INFO Name
  const handleSubmitEpargnePourcent = async () => {
    try {
      let data = qs.stringify({
        name: compInfo.name,
        address: compInfo.address,
        phone: compInfo.phone,
        payment_pourcent: compInfo.payment_pourcent,
        epargne_pourcent: epargne_pourcent,
      });

      const config1 = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${url}/v1/admin/company/info`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      const response = await axios(config1);

      if (response.status === 200) {
        toast.success("Modifications enregistrées avec succès");
        setPop1(!pop4);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Erreur lors de la modification des informations de l'entreprise",
      );
    }
  };

  // Admin List
  let config6 = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/get/col/day/pay`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchAllAdmin = async () => {
      await axios
        .request(config6)
        .then((response) => {
          setAminList(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAllAdmin();
  }, []);

  // Login To Super Admin
  let data2 = qs.stringify({
    code_telephone: "+228",
    numero_telephone: numero_telephone,
    mot_de_passe: mot_de_passe,
  });

  let config2 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/super/admin/login`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data2,
  };

  const superLogin = async () => {
    await axios
      .request(config2)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          sessionStorage.setItem("super_token", response.data.token);
          setTimeout(() => {
            setPopX(!popX);
            setShowModal(!showModal);
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setPopX(!popX);
        console.log(error);
      });
  };

  // Admin Signup
  let data5 = qs.stringify({
    nom: sNom,
    code_telephone: "+228",
    numero_telephone: sNumero_telephone,
    email: "admin@gmail.com",
    mot_de_passe: sMot_de_passe,
  });

  let config5 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/admin/signup`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${super_token}`,
    },
    data: data5,
  };

  const adminSignup = async () => {
    await axios
      .request(config5)
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          setTimeout(() => {
            setShowModal(false);
            window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Edite Phone
  let data10 = qs.stringify({
    phone: nber,
  });

  let config20 = {
    method: "patch",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/update/phonenumber/${aggId}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
    data: data10,
  };

  const editCompanyPhone = async () => {
    await axios
      .request(config20)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setTimeout(() => {
            fetchComp();
            setPopY(!popY);
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setPopY(!popY);
        console.log(error);
      });
  };

  // Admin Setting By super Admin
  let config11 = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/get/admin/settings/${adminID1}`,
    headers: {
      Authorization: `Bearer ${super_token}`,
    },
  };

  useEffect(() => {
    const getAdminSetting = async () => {
      await axios
        .request(config11)
        .then((response) => {
          setGetAdminSettings1(response.data.settings);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAdminSetting();
  }, [adminID1]);

  // States
  const [dashboard_auto, setDashboard_auto] = useState(false);
  const [controleur_auto, setControleur_auto] = useState(false);
  const [collecteur_auto, setCollecteur_auto] = useState(false);
  const [desistement_auto, setDesistement_auto] = useState(false);
  const [carnet_auto, setCarnet_auto] = useState(false);
  const [boutique_auto, setBoutique_auto] = useState(false);
  const [tasks_auto, setTasks_auto] = useState(false);
  const [repport_auto, setRepport_auto] = useState(false);
  const [settings_auto, setSettings_auto] = useState(false);

  // États pour les options d'administration
  const editAdminSettings = async () => {
    try {
      let data = {
        dashboard_auto: dashboard_auto ? 1 : 0,
        controleur_auto: controleur_auto ? 1 : 0,
        collecteur_auto: collecteur_auto ? 1 : 0,
        desistement_auto: desistement_auto ? 1 : 0,
        carnet_auto: carnet_auto ? 1 : 0,
        boutique_auto: boutique_auto ? 1 : 0,
        tasks_auto: tasks_auto ? 1 : 0,
        repport_auto: repport_auto ? 1 : 0,
        settings_auto: settings_auto ? 1 : 0,
      };

      const response = await axios.patch(
        `${url}/v1/super/admin/settings/${adminID1}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${super_token}`,
          },
        },
      );

      toast.success(response.data.message);
      setShowModal1(false);
    } catch (error) {
      console.error("Error editing admin settings:", error);
      setShowModal1(false);
    }
  };

  return (
    <>
      <div className="w-full h-[85vh] flex mt-[5%] px-3 overflow-auto scrollbar-hide">
        {/* Body */}
        <div className="p-2 flex flex-wrap">
          {/* Setting */}
          <div className="bg-slate-700 p-1 rounded-xl mt-2 h-[400px] m-2">
            {/* Title */}
            <div className="flex flex-row items-center mb-2">
              <p className="text-white text-lg font-bold ml-1 text-center">
                Information Entreprise
              </p>
            </div>

            <div className="h-[250px] w-[320px] flex flex-col justify-between">
              {/* Name */}
              <div className="bg-[#ADADAD36] p-2 rounded-xl justify-between flex flex-row w-full">
                <div className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full">
                  <FaRegBuilding color="#fff" />
                </div>

                <div className="border-none outline-none bg-transparen text-left text-white">
                  <p>{compInfo.name}</p>
                </div>

                <button
                  onClick={() => setPop1(!pop1)}
                  className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full"
                >
                  <FaPenAlt color="#fff" />
                </button>
              </div>

              {/* Adress */}
              <div className="bg-[#ADADAD36] my-3 p-2 rounded-xl justify-between flex flex-row w-full">
                <div className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full">
                  <LuMapPin color="#fff" />
                </div>

                <div className="border-none outline-none bg-transparen text-left text-white">
                  <p>{compInfo.address}</p>
                </div>

                <button
                  onClick={() => setPop2(!pop2)}
                  className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full"
                >
                  <FaPenAlt color="#fff" />
                </button>
              </div>

              {/* Phone */}
              <div className="bg-[#ADADAD36] p-2 rounded-xl justify-between flex flex-row w-full">
                <div className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full">
                  <MdOutlinePhone color="#fff" />
                </div>

                <div className="border-none outline-none bg-transparen text-left text-white">
                  <p>{compInfo.phone}</p>
                </div>

                <button
                  onClick={() => setPop3(!pop3)}
                  className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full"
                >
                  <FaPenAlt color="#fff" />
                </button>
              </div>

              {/* Pourcentage */}
              <div className="mt-4">
                {/* Pourcentage des produits */}
                <p className="my-1 text-white text-lg">Pourcentage Produit</p>
                <div className="bg-[#ADADAD36] p-2 rounded-xl justify-between flex flex-row w-full">
                  <div className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full">
                    <VscPercentage color="#fff" />
                  </div>

                  <div className="border-none outline-none bg-transparen text-left text-white">
                    <p>{compInfo.payment_pourcent}</p>
                  </div>

                  <button
                    onClick={() => setPop4(!pop4)}
                    className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full"
                  >
                    <FaPenAlt color="#fff" />
                  </button>
                </div>

                {/* Pourcentage Epargne */}
                <p className="my-1 text-white text-lg">Pourcentage Epargne</p>
                <div className="bg-[#ADADAD36] p-2 rounded-xl justify-between flex flex-row w-full">
                  <div className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full">
                    <VscPercentage color="#fff" />
                  </div>

                  <div className="border-none outline-none bg-transparen text-left text-white">
                    <p>{compInfo.epargne_pourcent}</p>
                  </div>

                  <button
                    onClick={() => setPop5(!pop5)}
                    className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full"
                  >
                    <FaPenAlt color="#fff" />
                  </button>
                </div>
              </div>
            </div>

            {/* All Pop */}
            <>
              {/* Pop 1 */}
              <div
                className={
                  !pop1
                    ? "hidden transition"
                    : "bg-slate-800 w-100 h-[200px] p-2 rounded-xl absolute top-[15%]  flex flex-col transition"
                }
              >
                <div className="flex justify-center">
                  <p className="text-white text-center">Nom Entreprise</p>

                  <button
                    onClick={() => setPop1(!pop1)}
                    className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full absolute right-1"
                  >
                    <IoMdClose color="#fff" />
                  </button>
                </div>

                <input
                  className="border-none outline-none rounded-xl text-black p-1 my-2"
                  placeholder="Nom Entreprise"
                  defaultValue={compInfo.name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                />

                {/* Confim Edit */}
                <div className="w-full flex items-center justify-center mt-3">
                  <button
                    onClick={() => handleSubmitName()}
                    className={
                      agc.length === 0 || agcNumber.length === 0
                        ? "bg-blue-600 p-2 rounded-md hover:bg-slate-500 opacity-40"
                        : "bg-blue-600 p-2 rounded-md hover:bg-slate-500"
                    }
                  >
                    <p className="text-white font-bold">Sauvegarde</p>
                  </button>
                </div>
              </div>

              {/* Pop 2 */}
              <div
                className={
                  !pop2
                    ? "hidden transition"
                    : "bg-slate-800 w-100 h-[200px] p-2 rounded-xl absolute top-[15%]  flex flex-col transition"
                }
              >
                <div className="flex justify-center">
                  <p className="text-white text-center">Adress</p>

                  <button
                    onClick={() => setPop2(!pop2)}
                    className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full absolute right-1"
                  >
                    <IoMdClose color="#fff" />
                  </button>
                </div>

                <input
                  className="border-none outline-none rounded-xl text-black p-1 my-2"
                  placeholder="Adress"
                  defaultValue={compInfo.address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                />

                {/* Confim Edit */}
                <div className="w-full flex items-center justify-center mt-3">
                  <button
                    onClick={() => handleSubmitAdress()}
                    className={
                      agc.length === 0 || agcNumber.length === 0
                        ? "bg-blue-600 p-2 rounded-md hover:bg-slate-500 opacity-40"
                        : "bg-blue-600 p-2 rounded-md hover:bg-slate-500"
                    }
                  >
                    <p className="text-white font-bold">Sauvegarde</p>
                  </button>
                </div>
              </div>

              {/* Pop 3 */}
              <div
                className={
                  !pop3
                    ? "hidden transition"
                    : "bg-slate-800 w-100 h-[200px] p-2 rounded-xl absolute top-[15%]  flex flex-col transition"
                }
              >
                <div className="flex justify-center">
                  <p className="text-white text-center">Téléphone</p>

                  <button
                    onClick={() => setPop3(!pop3)}
                    className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full absolute right-1"
                  >
                    <IoMdClose color="#fff" />
                  </button>
                </div>

                <input
                  className="border-none outline-none rounded-xl text-black p-1 my-2"
                  placeholder="Telephone"
                  maxLength={8}
                  defaultValue={compInfo.phone}
                  onChange={(e) => setPhone1(e.target.value)}
                  type="text"
                />

                {/* Confim Edit */}
                <div className="w-full flex items-center justify-center mt-3">
                  <button
                    onClick={() => handleSubmitPhone()}
                    className={
                      agc.length === 0 || agcNumber.length === 0
                        ? "bg-blue-600 p-2 rounded-md hover:bg-slate-500 opacity-40"
                        : "bg-blue-600 p-2 rounded-md hover:bg-slate-500"
                    }
                  >
                    <p className="text-white font-bold">Sauvegarde</p>
                  </button>
                </div>
              </div>

              {/* Pop 4 */}
              <div
                className={
                  !pop4
                    ? "hidden transition"
                    : "bg-slate-800 w-100 h-[200px] p-2 rounded-xl absolute top-[15%]  flex flex-col transition"
                }
              >
                <div className="flex justify-center">
                  <p className="text-white text-center">Pourcentage</p>

                  <button
                    onClick={() => setPop4(!pop4)}
                    className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full absolute right-1"
                  >
                    <IoMdClose color="#fff" />
                  </button>
                </div>

                <input
                  className="border-none outline-none rounded-xl text-black p-1 my-2"
                  placeholder="Pourcentage"
                  maxLength={2}
                  defaultValue={compInfo.payment_pourcent}
                  onChange={(e) => setPayment_pourcent(e.target.value)}
                  type="text"
                />

                {/* Confim Edit */}
                <div className="w-full flex items-center justify-center mt-3">
                  <button
                    onClick={() => handleSubmitProductPourcent()}
                    className={
                      agc.length === 0 || agcNumber.length === 0
                        ? "bg-blue-600 p-2 rounded-md hover:bg-slate-500 opacity-40"
                        : "bg-blue-600 p-2 rounded-md hover:bg-slate-500"
                    }
                  >
                    <p className="text-white font-bold">Sauvegarde</p>
                  </button>
                </div>
              </div>

              {/* Pop 5 */}
              <div
                className={
                  !pop5
                    ? "hidden transition"
                    : "bg-slate-800 w-100 h-[200px] p-2 rounded-xl absolute top-[15%]  flex flex-col transition"
                }
              >
                <div className="flex justify-center">
                  <p className="text-white text-center text">Epargne</p>

                  <button
                    onClick={() => setPop5(!pop5)}
                    className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full absolute right-1"
                  >
                    <IoMdClose color="#fff" />
                  </button>
                </div>

                <input
                  className="border-none outline-none rounded-xl text-black p-1 my-2"
                  placeholder="Pourcentage"
                  maxLength={2}
                  defaultValue={compInfo.epargne_pourcent}
                  onChange={(e) => setEpargne_pourcent(e.target.value)}
                  type="text"
                />

                {/* Confim Edit */}
                <div className="w-full flex items-center justify-center mt-3">
                  <button
                    onClick={() => handleSubmitEpargnePourcent()}
                    className={
                      agc.length === 0 || agcNumber.length === 0
                        ? "bg-blue-600 p-2 rounded-md hover:bg-slate-500 opacity-40"
                        : "bg-blue-600 p-2 rounded-md hover:bg-slate-500"
                    }
                  >
                    <p className="text-white font-bold">Sauvegarde</p>
                  </button>
                </div>
              </div>
            </>
          </div>

          {/* Admin Liste */}
          <div className="bg-slate-700 w-[315px] rounded-xl h-[400px] m-2 file:overflow-auto scrollbar-hide">
            {/* Title */}
            <div className="flex flex-row items-center mb-2 w-full">
              <p className="text-white text-lg font-bold ml-[65px]">
                Liste des Admins ({adminList.length})
              </p>

              <button
                onClick={() => setPopX(!popX)}
                className="p-2 w-15 ml-5 h-1/5"
              >
                <CiCirclePlus color="white" size={30} />
              </button>
            </div>

            {/* Actions */}
            <div className="h-[280px] flex flex-col overflow-auto scrollbar-hide p-3">
              {/* Admin List */}
              {adminList.map(
                ({
                  id,
                  nom,
                  numero_telephone,
                  email,
                  visible_passe,
                  date_creation,
                }) => (
                  <div
                    key={id}
                    className="bg-[#ADADAD36] p-2 rounded-xl my-1 justify-between flex flex-row w-full"
                  >
                    <div className="flex flex-col justify-between">
                      <div className="bg-[#ADADAD36] flex justify-center items-center h-[30px] w-[30px] p-2 rounded-full">
                        <RiUser3Line color="#fff" />
                      </div>

                      {/* Edite Admin Settings */}
                      <button
                        disabled
                        onClick={() => {
                          setShowModal1(!showModal1);
                          setAdminID1(id);
                        }}
                        className="bg-slate-900 hover:bg-slate-500 p-1.5 rounded-full"
                      >
                        <GrUserSettings color="white" size={20} />
                      </button>
                    </div>

                    <div>
                      <p className="text-white">{nom}</p>
                      <p className="text-white">{numero_telephone}</p>
                      <p className="text-white">{email}</p>
                      <p className="text-white"> Passe : {visible_passe}</p>
                      <p className="text-white">
                        creation : {moment(date_creation).calendar()}
                      </p>
                    </div>
                  </div>
                ),
              )}
            </div>

            {/* Popp X */}
            <div
              className={
                !popX
                  ? "hidden transition"
                  : "bg-slate-800 w-100 h-[200px]  p-2 rounded-xl absolute top-[15%] ml-3 flex flex-col transition"
              }
            >
              <p className="text-white text-center">Super Admin Connect</p>

              <input
                className="border-none outline-none rounded-xl text-black p-1 my-2"
                placeholder="Super Login"
                maxLength={8}
                value={numero_telephone}
                onChange={(e) => setNumero_telephone(e.target.value)}
                type="text"
              />

              <input
                className="border-none outline-none rounded-xl text-black p-1 my-2"
                placeholder="Super Passe"
                value={mot_de_passe}
                onChange={(e) => setMot_de_passe(e.target.value)}
                type="text"
              />

              {/* Add Button */}
              <div className="w-full flex items-center justify-center mt-3">
                <button
                  onClick={() => superLogin()}
                  disabled={
                    numero_telephone.length === 0 || mot_de_passe.length === 0
                      ? true
                      : false
                  }
                  className={
                    numero_telephone.length === 0 || mot_de_passe.length === 0
                      ? "bg-blue-600 p-2 rounded-md hover:bg-slate-500 opacity-40"
                      : "bg-blue-600 p-2 rounded-md hover:bg-slate-500"
                  }
                >
                  <p className="text-white font-bold">Super Connect</p>
                </button>
              </div>
            </div>
          </div>

          {/* Phone number Liste */}
          <div className="bg-slate-700 w-[250px] p-1 rounded-xl mt-2 h-[400px] m-2 file:overflow-auto scrollbar-hide">
            {/* Title */}
            <div className="flex flex-row items-center mb-2 w-full">
              <p className="text-white text-lg font-bold ml-10">
                Liste des Numeros
              </p>

              <button onClick={() => setPop(!pop)} className="p-2 w-15 h-1/5">
                <CiCirclePlus color="white" size={30} />
              </button>
            </div>

            <div className="h-[280px] flex flex-col overflow-auto scrollbar-hide p-3">
              {/* Phone */}
              {phone.map(({ id, Agence_id, Agence, Number }) => (
                <div
                  key={id}
                  className="bg-[#ADADAD36] p-2 rounded-xl my-1 justify-between flex flex-row w-full"
                >
                  <div className="flex flex-col justify-between h-full">
                    <div className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full">
                      <MdOutlinePhone color="#fff" size={15} />
                    </div>

                    <button
                      onClick={() => {
                        setPopY(!popY);
                        setNber(Number);
                        setAggId(Agence_id);
                        console.log(aggId);
                      }}
                      className="bg-[#ADADAD36] p-2 w-[30px] h-[30px] rounded-full mt-2"
                    >
                      <FaPencil color="#fff" size={15} />
                    </button>
                  </div>

                  <div>
                    <p className="text-white">{Agence}</p>
                    <p className="text-white mt-2"> (228) {Number}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Popp */}
            <div
              className={
                !pop
                  ? "hidden transition"
                  : "bg-slate-800 w-100 h-[200px] p-2 rounded-xl absolute top-[15%] ml-3 flex flex-col transition"
              }
            >
              <p className="text-white text-center">Ajouter un Numéro</p>

              {/* Edite Agency */}
              <button
                onClick={() => setOpen(!open)}
                className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4 w-full justify-between"
              >
                <div className="flex justify-between items-center">
                  <PiBuildingOffice size={20} color="#fff" />
                  <p className="text-white ml-3">
                    {selectName || "Trouver l'Agence"}
                  </p>
                </div>

                <IoIosArrowDown size={20} color="#fff" />
              </button>

              <input
                className="border-none outline-none rounded-md text-black p-1 my-2"
                placeholder="Numero"
                maxLength={8}
                value={agcNumber}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^\d.]/g, "");
                  setAgcNumber(filteredInput);
                }}
                type="text"
              />

              {/* Add Button */}
              <div className="w-full flex items-center justify-center mt-3">
                <button
                  onClick={() => addNewNumber()}
                  disabled={
                    agc.length === 0 || agcNumber.length === 0 ? true : false
                  }
                  className={
                    agc.length === 0 || agcNumber.length === 0
                      ? "bg-blue-600 p-2 rounded-md hover:bg-slate-500 opacity-40"
                      : "bg-blue-600 p-2 rounded-md hover:bg-slate-500"
                  }
                >
                  <p className="text-white font-bold">Ajouter le numéro</p>
                </button>
              </div>
            </div>

            {/* Popp Y */}
            <div
              className={
                !popY
                  ? "hidden transition"
                  : "bg-slate-800 w-100 h-[200px]  p-2 rounded-xl absolute top-[15%] ml-3 flex flex-col transition"
              }
            >
              <div className="flex flex-row items-center justify-center">
                <p className="text-white text-center">Modifier le numero</p>

                <button
                  onClick={() => setPopY(!popY)}
                  className="p-1 absolute right-1 bg-[#ADADAD36] rounded-full"
                >
                  <IoMdClose color="white" />
                </button>
              </div>

              <input
                className="border-none outline-none rounded-xl text-black p-1 my-2"
                maxLength={8}
                defaultValue={nber}
                onChange={(e) => {
                  const input = e.target.value;
                  const filteredInput = input.replace(/[^\d.]/g, "");
                  setNber(filteredInput);
                }}
                type="text"
              />

              {/* Edit Button */}
              <div className="w-full flex items-center justify-center mt-3">
                <button
                  onClick={() => editCompanyPhone()}
                  disabled={nber.length === 0 ? true : false}
                  className={
                    nber.length === 0
                      ? "bg-blue-600 p-2 rounded-md hover:bg-slate-500 opacity-40"
                      : "bg-blue-600 p-2 rounded-md hover:bg-slate-500"
                  }
                >
                  <p className="text-white font-bold">Sauvegader</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pop Admin settings */}
      <div
        className={
          !showModal1
            ? "hidden"
            : "flex w-9/12 absolute top-0 bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal1
              ? "hidden"
              : "absolute w-[350px] h-[500px] text-center flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="mt-3">
            {/* Title */}
            <p className="text-white font-bold text-xl text-center">
              Autorisation Admin
            </p>

            <div className="flex flex-row justify-center">
              <button
                onClick={() => setShowModal1(!showModal1)}
                className="bg-[#ADADAD36] absolute right-2 top-2 rounded-full flex justify-center items-center"
              >
                <IoMdClose color="white" size={25} />
              </button>
            </div>
          </div>

          {/* Forms */}
          <div className="h-full flex flex-col mt-2">
            <div className=" h-full px-2 flex flex-col justify-between">
              {/* Dash */}
              <div className="w-full flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <AiOutlineDashboard color="white" size={30} />
                  <p className="text-white text-lg ml-2">Dashboard</p>
                </div>

                <Switch
                  defaultChecked={dashboard_auto}
                  onChange={() => setDashboard_auto(!dashboard_auto)}
                  checked={dashboard_auto}
                />
              </div>

              {/* Control */}
              <div className="w-full flex flex-row items-center my-2 justify-between">
                <div className="flex flex-row items-center">
                  <LuUser2 color="white" size={30} />
                  <p className="text-white text-lg ml-2">Contrôleur</p>
                </div>

                <Switch
                  defaultChecked={getAdminSetting1.controleur_auto}
                  onChange={() => setControleur_auto(!controleur_auto)}
                  checked={controleur_auto}
                />
              </div>

              {/* Collector */}
              <div className="w-full flex flex-row items-center my-2 justify-between">
                <div className="flex flex-row items-center">
                  <TbUsers color="white" size={30} />
                  <p className="text-white text-lg ml-2">Collecteur</p>
                </div>

                <Switch
                  defaultChecked={getAdminSetting1.collecteur_auto}
                  onChange={() => setCollecteur_auto(!collecteur_auto)}
                  checked={collecteur_auto}
                />
              </div>

              {/* Desistement */}
              <div className="w-full flex flex-row items-center my-2 justify-between">
                <div className="flex flex-row items-center">
                  <TbCreditCardOff color="white" size={30} />
                  <p className="text-white text-lg ml-2">Desistement</p>
                </div>

                <Switch
                  defaultChecked={getAdminSetting1.desistement_auto}
                  onChange={() => setDesistement_auto(!desistement_auto)}
                  checked={desistement_auto}
                />
              </div>

              {/* Carnet */}
              <div className="w-full flex flex-row items-center my-2 justify-between">
                <div className="flex flex-row items-center">
                  <PiCardsThreeLight color="white" size={30} />
                  <p className="text-white text-lg ml-2">Carnet</p>
                </div>

                <Switch
                  defaultChecked={getAdminSetting1.carnet_auto}
                  onChange={() => setCarnet_auto(!carnet_auto)}
                  checked={carnet_auto}
                />
              </div>

              {/* Boutique */}
              <div className="w-full flex flex-row items-center my-2 justify-between">
                <div className="flex flex-row items-center">
                  <MdOutlineShoppingCart color="white" size={30} />
                  <p className="text-white text-lg ml-2">Boutique</p>
                </div>

                <Switch
                  defaultChecked={getAdminSetting1.boutique_auto}
                  onChange={() => setBoutique_auto(!boutique_auto)}
                  checked={boutique_auto}
                />
              </div>

              {/* Tasks */}
              <div className="w-full flex flex-row items-center my-2 justify-between">
                <div className="flex flex-row items-center">
                  <LiaTasksSolid color="white" size={30} />
                  <p className="text-white text-lg ml-2">Tâches</p>
                </div>

                <Switch
                  defaultChecked={getAdminSetting1.tasks_auto}
                  onChange={() => setTasks_auto(!tasks_auto)}
                  checked={tasks_auto}
                />
              </div>

              {/* Control */}
              <div className="w-full flex flex-row items-center my-2 justify-between">
                <div className="flex flex-row items-center">
                  <TbReportSearch color="white" size={30} />
                  <p className="text-white text-lg ml-2">Rapport</p>
                </div>

                <Switch
                  defaultChecked={getAdminSetting1.repport_auto}
                  onChange={() => setRepport_auto(!repport_auto)}
                  checked={repport_auto}
                />
              </div>

              {/* Control */}
              <div className="w-full flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <CiSettings color="white" size={30} />
                  <p className="text-white text-lg ml-2">Paramètre</p>
                </div>

                <Switch
                  defaultChecked={getAdminSetting1.settings_auto}
                  onChange={() => setSettings_auto(!settings_auto)}
                  checked={!settings_auto}
                />
              </div>
            </div>
          </div>

          {/* Confirme */}
          <button
            onClick={editAdminSettings}
            className="p-3 bg-blue-600 hover:bg-blue-400 mt-2 rounded-b-xl"
          >
            <p className="text-white">Sauvegarder</p>
          </button>
        </div>
      </div>

      {/* Pop Zz */}
      <div
        className={
          !showModal
            ? "hidden"
            : "flex w-9/12 absolute top-0 bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal
              ? "hidden"
              : "absolute w-[310px] h-[380px] text-center flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#1c00f129]">
              <PiUserPlusLight size={25} color="blue" />
            </div>
          </div>

          <div className="flex flex-row justify-center">
            {/* Title */}
            <p className="text-white font-bold text-xl mt-[70px] text-center">
              Créateur Admin
            </p>

            <button
              onClick={() => setShowModal(!showModal)}
              className="bg-[#ADADAD36] absolute right-2 top-2 rounded-full flex justify-center items-center"
            >
              <IoMdClose color="white" size={25} />
            </button>
          </div>

          {/* Forms */}
          <div className="h-[260px] flex flex-col mt-2">
            {/* Nom */}
            <div>
              <input
                placeholder="Nom"
                className="p-3 bg-[#ADADAD36] border-none outline-none text-white"
                value={sNom}
                onChange={(e) => setSNom(e.target.value)}
              />
            </div>

            {/* Telephone */}
            <div>
              <input
                placeholder="Telephone"
                maxLength={8}
                className="my-5 p-3 bg-[#ADADAD36] border-none outline-none text-white"
                value={sNumero_telephone}
                onChange={(e) => setSNumero_telephone(e.target.value)}
              />
            </div>

            {/* Password */}
            <div>
              <input
                placeholder="Mot de passe"
                className="p-3 bg-[#ADADAD36] border-none outline-none text-white"
                value={sMot_de_passe}
                onChange={(e) => setSMot_de_passe(e.target.value)}
              />

              {/* Generate Ico */}
              <div
                title="Générer un mot de passe"
                className="flex p-2 bottom-[25%] right-2 rounded-md bg-[#ADADAD36] hover:bg-slate-700 absolute justify-center items-center"
              >
                <button onClick={() => setSMot_de_passe(makeid(5))}>
                  <SlRefresh color="white" className="animate-spin" />
                </button>
              </div>
            </div>
          </div>

          {/* Button */}
          <div className="px-[10%] w-full flex justify-center mb-5">
            {/* Create Admin  */}
            <button
              disabled={
                sNom.length === 0 ||
                sNumero_telephone.length === 0 ||
                sMot_de_passe.length === 0
              }
              onClick={() => {
                adminSignup();
              }}
              className={
                sNom.length === 0 ||
                sNumero_telephone.length === 0 ||
                sMot_de_passe.length === 0
                  ? "w-full h-10 bg-red-600 rounded-md hover:bg-slate-600 mx-2 opacity-40"
                  : "w-full h-10 bg-red-600 rounded-md hover:bg-slate-600 mx-2 opacity-40"
              }
            >
              <p className="text-white font-medium text-base">Crée un Admin</p>
            </button>
          </div>
        </div>
      </div>

      {/* Agency List */}
      <div className="flex justify-center">
        <div
          className={
            !open
              ? "hidden transition-all"
              : "absolute w-[250px] flex flex-col justify-center  items-center bg-black max-sm:bg-slate-500 rounded-xl max-h-[250px] max-sm:h-[200px] mb-9 max-sm:mb-20 top-[180px] z-10 transition"
          }
        >
          <div className="flex flex-row items-center h-[30px]">
            <PiBuildingOffice size={20} color="#fff" className="mt-2" />
            <p className="text-white text-lg font-semibold ml-3 mt-2">
              List des Agences
            </p>
          </div>

          {/* Divider */}
          <div className="w-full h-[1px] bg-slate-100 my-2" />

          {/* Body */}
          <div className="overflow-auto scrollbar-hide flex flex-col">
            {loading ? (
              <div className="w-full h-full flex flex-col justify-center items-center p-3">
                <img src={Refresh} className="w-12 h-12" />
                <p className="text-white">Recherche Agence...</p>
              </div>
            ) : (
              agence?.length > 0 &&
              agence &&
              agence?.map(({ id, nom_agence }) => (
                <button
                  key={id}
                  onClick={() => {
                    setSelect(id);
                    setSelectName(nom_agence);
                    setAgcId(id);
                    setAgc(nom_agence);
                    setOpen(false);
                  }}
                  className={
                    select === id
                      ? "bg-slate-700 p-2"
                      : "hover:bg-slate-700 p-2"
                  }
                >
                  <p className="text-white">{nom_agence}</p>
                </button>
              ))
            )}
          </div>
        </div>
      </div>

      {/* Container */}
      <ToastContainer />
    </>
  );
}

export default Seettings;
