import React, { useEffect, useState } from "react";
import Info from "./client_details/Info";
import GeoLocate from "./client_details/GeoLocate";
import { useParams, useNavigate } from "react-router-dom";
import Config from "./../../config/Links";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import { IoArrowBack } from "react-icons/io5";
const url = Config.GESCAPO_LINK;
const token = sessionStorage.getItem("gestoken");

// Nav
const navigation = [
  { id: 0, name: "Liste des Carnets" },
  { id: 1, name: "Liste des cautisations" },
];

function InfoClient() {
  const navigations = useNavigate();
  moment.locale("fr");
  const { id } = useParams();

  const [client, setClient] = useState({});
  const [carnets, setCarnets] = useState([]);
  const [cotisation, setCotisation] = useState([]);
  const [isSelect, setIsSelect] = useState(0);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${url}/api/auth/admin/client/info/${id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.request(config);
        setClient(response.data.data);
        setCarnets(response.data.data.carnets);
      } catch (error) {
        console.log(error);
      }
    };

    fetchClient();
  }, [id]);

  return (
    <div className="flex flex-row items-start">
      {/* SECTION GAUCHE */}
      <div>
        {/* Back */}
        <button
          onClick={() => navigations(-1)}
          className="bg-[#ADADAD36] p-3 absolute top-14 m-2 rounded-md hover:bg-slate-500"
        >
          <IoArrowBack color="white" />
        </button>

        <p className="mt-10">Information</p>
        <Info
          nom={client.nom}
          prenom={client.prenom}
          numero={client.numero}
          profession={client.profession}
        />

        {/* Localisation Client */}
        <p className="text-red-500 text-lg my-2 font-bold mt-5">
          Localistion du client
        </p>
        <GeoLocate
          latitt={6.3786908 || 0}
          longitt={0.9840086 || 0}
          client_name={`${client?.nom} ${client?.prenom}`}
        />
      </div>

      {/* Section Droite */}
      <div className="mt-[65px] flex flex-col w-8/12">
        {/* Nav */}
        <div className="flex justify-center items-center w-full">
          <div className="flex flex-row bg-[#ADADAD36] w-[330px] p-1 justify-between rounded-md">
            {navigation.map(({ id, name }) => {
              return (
                <button
                  onClick={() => setIsSelect(id)}
                  key={id}
                  className={
                    id === isSelect
                      ? "bg-blue-500 rounded-md text-white p-1.5 flex flex-col"
                      : "text-white p-1.5 flex flex-col"
                  }
                >
                  <p>{name}</p>

                  {id == 0 && <p className="text-red-500">{carnets.length}</p>}
                  {id == 1 && <p className="text-red-500">0</p>}
                </button>
              );
            })}
          </div>
        </div>

        {/* BOdy */}
        {isSelect === 0 ? (
          <div className="p-1 flex flex-col justify-center items-center max-h-[80vh] overflow-auto scrollbar-hide">
            {carnets.map((item) => (
              <div
                key={item.id}
                className={
                  item.nbcotisattion === 372
                    ? "flex flex-row w-3/5 justify-between bg-slate-500 h-[auto] p-2 my-2 rounded-md opacity-40"
                    : "flex flex-row w-3/5 justify-between bg-slate-500 h-[auto] p-2 my-2 rounded-md"
                }
              >
                <div>
                  <p className="text-white">ID : {item.ids}</p>
                  <p className="text-white">{item.carnet.libelle}</p>

                  <p className="text-white">
                    Cotisation : {item.nbcotisattion} / 372 fois
                  </p>

                  <p className="text-white">
                    {" "}
                    S. Total :{" "}
                    {(item.nbcotisattion * item.carnet.tarif).toLocaleString(
                      "fr-FR",
                    )}{" "}
                    FCFA
                  </p>

                  <p className="text-white">
                    {" "}
                    Opérations : {moment(
                      item.operations.premier,
                    ).calendar()} - {moment(item.operations.dernier).calendar()}
                  </p>

                  <p className="text-white">
                    {" "}
                    Date de creation : {moment(item.created_at).calendar()}
                  </p>

                  <p
                    className={
                      item.nbcotisattion === 372
                        ? "text-red-400"
                        : "text-green-400"
                    }
                  >
                    Status :{" "}
                    {item.nbcotisattion === 372 ? "Terminé" : "En cours"}
                  </p>
                </div>

                <div className="flex flex-col justify-end items-end w-[150px]">
                  <p className="text-white text-xs">
                    Mise : {item.carnet.tarif.toLocaleString("fr-FR")} FCFA
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          isSelect === 1 && (
            <div className="p-1 flex flex-col justify-center items-center">
              <div className="flex flex-col w-3/5 bg-slate-500 p-2 my-2">
                <p className="text-white">????</p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default InfoClient;
