import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import { IoMdClose } from "react-icons/io";
import Config from "../../../config/Links";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Loading_blue from "../../../assets/svg/loading_blue.json";

const LastCotisations = () => {
  moment.locale("fr");

  // State
  const [loading0, setLoading0] = useState(true);
  const [loading, setLoading] = useState(true);
  const [colListe, setColListe] = useState([]);
  const [colID, setColID] = useState("");
  const [colName, setColName] = useState("");

  // Fetch All Coll info
  useEffect(() => {
    const fetchAllColl = async () => {
      const token = sessionStorage.getItem("gestoken");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Config.GESCAPO_LINK}/api/auth/admin/agent_collecteur/list?all=true`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setLoading0(false);
          setColListe(response.data.data);
        })
        .catch((error) => {
          setLoading0(false);
          console.log(error);
        });
    };
    fetchAllColl();
  }, []);

  // Default date controle
  const weekSubstract = moment().subtract(30, "days").format("DD.MM.YYYY");
  const currentDate = moment().format("YYYY-MM-DD");

  // State
  const [cotisationListe, setCotisationListe] = useState([]);
  const [dateA, setDateA] = useState(weekSubstract);
  const [modal, setModal] = useState(false);

  // Last Cotisation rendered
  useEffect(() => {
    lastCotisationsFilter();
  }, [colID, dateA]);

  const lastCotisationsFilter = () => {
    const token = sessionStorage.getItem("gestoken");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.GESCAPO_LINK}/api/auth/admin/v4/collecteur/cotisation?
      id_collecteur=${colID}&startDate=${dateA}&endDate=${currentDate}&all=true`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setCotisationListe(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {/* Coll LISTE */}
      {loading0 ? (
        <Player
          autoplay
          loop
          src={Loading_blue}
          style={{ height: "100px", width: "100px" }}
        />
      ) : (
        <div className="grid grid-cols-5 overflow-auto h-[88vh]">
          {colListe.map((col, index) => {
            return (
              <div
                onClick={() => {
                  setColID(col.id);
                  setColName(col.nom + " " + col.prenom);
                  setModal(true);
                }}
                key={col.id}
                className="m-2 p-3 bg-[#aaaaaa24] rounded-md cursor-pointer"
              >
                <h2 className="text-white">
                  {col.nom} {col.prenom}
                </h2>

                {/* Number of Client */}
                <p className="text-yellow-500 text-sm">
                  {col.nbr_cli} client(s)
                </p>
              </div>
            );
          })}
        </div>
      )}

      {/* Last cotisation modal */}
      <div
        className={
          modal
            ? "flex flex-col absolute top-20 z-10 justify-center items-center bg-[#00000034] w-10/12 h-[90%]"
            : "hidden"
        }
      >
        {/* Container */}
        <div className="w-8/12 h-full bg-[#1a1a1a] shadow-md rounded-xl">
          {/* Filter Bar */}
          <div className="flex flex-col items-center">
            <h3 className="text-white my-3 font-semibold">
              Cotisation des clients de ({colName})
            </h3>

            {/* Date Filter */}
            <div className="flex w-full px-32 justify-between">
              {/* from */}
              <div className="flex items-center">
                <h2 className="text-white text-xl mr-4"> Du </h2>
                <input
                  type="date"
                  defaultValue={dateA}
                  onChange={(e) => setDateA(e.target.value)}
                  className="bg-white p-2 rounded-md shadow-md outline-none border-none"
                />
              </div>

              {/* To */}
              <div className="flex items-center">
                <h2 className="text-white text-xl mr-4">Au</h2>
                <input
                  type="date"
                  disabled
                  value={currentDate}
                  className="bg-white p-2 rounded-md shadow-md outline-none border-none"
                />
              </div>
            </div>

            <button
              onClick={() => {
                setModal(false);
                setCotisationListe([]);
              }}
              className="rounded-full bg-[#aaaaaa34] hover:bg-gray-600 absolute mr-5 w-[30px] h-[30px] flex right-[16%] top-2  justify-center items-center"
            >
              <IoMdClose color="white" />
            </button>
          </div>

          {/* Liste */}
          {loading ? (
            <Player
              autoplay
              loop
              src={Loading_blue}
              style={{ height: "100px", width: "100px" }}
            />
          ) : (
            <div className="w-full mt-3 bg-[#000000] overflow-auto h-[80vh]">
              {cotisationListe.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className="flex flex-col justify-center items-start p-3 border-b-[#1a1a1a]"
                  >
                    {/* carnet Label */}
                    <p className="text-white">{item.carnetLabel}</p>

                    {/* Client Name */}
                    <h2 className="text-green-500 font-bold">
                      Client : {item.nomClient} {item.prenomClient}
                    </h2>

                    {/* Mise */}
                    <p className="text-white">Mise : {item.mise} F</p>

                    {/* Nbre de cotisation */}
                    <p className="text-white">
                      Nbre de cotisation : {item.nbCotisation} Fois
                    </p>

                    {/* montant */}
                    <p className="text-white">
                      Montant :{" "}
                      {parseInt(item.totalCotiser).toLocaleString("fr-FR")} F
                    </p>

                    {/* Date */}
                    <p className="text-red-500">
                      Date : {moment(item.dateCotisation).format("DD.MM.YYYY")}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LastCotisations;
