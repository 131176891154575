import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import HeaderLgg from "./HeaderLgg";

// Menu Ico
import { MdOutlineDashboard } from "react-icons/md";
import { PiCardsThreeLight } from "react-icons/pi";
import { RiUserStarLine } from "react-icons/ri";
import { TbUsers } from "react-icons/tb";
import { FaRegBuilding } from "react-icons/fa";
import { BsGift } from "react-icons/bs";
import { MdAddShoppingCart } from "react-icons/md";
import { RiStore2Line } from "react-icons/ri";
import { CiSettings } from "react-icons/ci";

const menu = [
  {
    id: 0,
    name: "Dashboard",
    route: "/dashboard",
    icon: <MdOutlineDashboard size={25} className="text-white" />,
  },
  {
    id: 1,
    name: "Carnet",
    route: "/dashboard/carnet/lgg",
    icon: <PiCardsThreeLight size={25} className="text-white" />,
  },
  {
    id: 2,
    name: "Vendeurs",
    route: "/dashboard/vendeur/lgg",
    icon: <RiUserStarLine size={25} className="text-white" />,
  },
  {
    id: 3,
    name: "Clients",
    route: "/dashboard/clients/lgg",
    icon: <TbUsers size={25} className="text-white" />,
  },
  {
    id: 4,
    name: "Agence",
    route: "/dashboard/agence/lgg",
    icon: <FaRegBuilding size={25} className="text-white" />,
  },
  {
    id: 5,
    name: "Bonus",
    route: "/dashboard/bonus/lgg",
    icon: <BsGift size={25} className="text-white" />,
  },
  {
    id: 6,
    name: "Commandes",
    route: "/dashboard/orders/lgg",
    icon: <MdAddShoppingCart size={25} className="text-white" />,
  },
  {
    id: 7,
    name: "Magasin",
    route: "/dashboard/magasin/lgg",
    icon: <RiStore2Line size={25} className="text-white" />,
  },
  {
    id: 8,
    name: "Paramètre",
    route: "/dashboard/settings/lgg",
    icon: <CiSettings size={25} className="text-white" />,
  },
];

function NavigationLgg() {
  const [select, setSelect] = useState(0);

  return (
    <>
      <div className="flex flex-row w-[100%]">
        {/* Big Screen Menu */}
        <div className="max-sm:hidden w-[18%] max-sm:w-[0px] px-10 bg-[#0f0f0f] max-sm:h-0 max-sm:invisible h-screen">
          <nav className="my-20">
            {menu.map((item) => {
              return (
                <Link
                  key={item.id}
                  to={item.route}
                  onClick={() => setSelect(item.id)}
                >
                  <div
                    className={
                      select == item.id
                        ? "bg-[#020ccf24] flex flex-row p-4 rounded-md max-sm:w-0 max-sm:h-0"
                        : "flex flex-row hover:bg-[#ADADAD24] p-3 rounded-md max-sm:w-0 max-sm:h-0"
                    }
                  >
                    <div>{item.icon}</div>
                    <p
                      className={
                        select === item.id
                          ? "z-1 text-[#020ccf] text-base ml-3"
                          : "z-1 text-gray-500 text-base ml-3"
                      }
                    >
                      {item.name}
                    </p>
                  </div>
                </Link>
              );
            })}
          </nav>
        </div>

        {/* Render */}
        <div className="w-full max-sm:left-0 bg-black max-sm:w-[100%]">
          <HeaderLgg />
          <Outlet />
        </div>
      </div>

      {/* Mobile Menu */}
      <div className="bg-[#0f0f0f] fixed w-full h-[70px] left-0 right-0 bottom-0 justify-center items-center max-sm:visible md:hidden xl:hidden 2xl:hidden lg:hidden">
        <nav className="w-full justify-around items-center flex flex-row mt-1.5">
          {menu.map((item) => {
            return (
              <Link
                key={item.id}
                to={item.route}
                onClick={() => setSelect(item.id)}
              >
                <button
                  className={
                    select === item.id
                      ? "bg-[#ADADAD36] w-[55px] h-[55px] flex items-center rounded-md justify-center"
                      : ""
                  }
                >
                  <div>{item.icon}</div>
                </button>
              </Link>
            );
          })}
        </nav>
      </div>
    </>
  );
}

export default NavigationLgg;
