import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { RiUser6Line } from "react-icons/ri";
import { LuUsers2 } from "react-icons/lu";
import { GrGroup } from "react-icons/gr";
import { PiPackage } from "react-icons/pi";
import { TbCardsFilled } from "react-icons/tb";
import { RiShoppingCartLine } from "react-icons/ri";
import { MdCreditCardOff } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { MdOutlineHistoryToggleOff } from "react-icons/md";
import { PiCardsThreeLight } from "react-icons/pi";
import { IoMdClose } from "react-icons/io";
import { FaCircleUser } from "react-icons/fa6";
import { LuShoppingCart } from "react-icons/lu";
import { BsCartCheck } from "react-icons/bs";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { MdOutlineGroupOff } from "react-icons/md";
import Loading from "../../assets/rff.svg";
import Config from "../../config/Links";
import { Link } from "react-router-dom";
import moment from "moment";
import { CiFilter } from "react-icons/ci";
import "moment/locale/fr";
import AgencesListe from "./AgenceListe";

const url = Config.G_PERFORM_LINK;
const geSUrl = Config.GESCAPO_LINK;
const token = sessionStorage.getItem("admin_tk");

const state = [
  {
    id: 0,
    name: "Contrôleurs",
    icon: <RiUser6Line size={35} color="#22c55e" />,
  },
  { id: 1, name: "Collecteurs", icon: <LuUsers2 size={35} color="#ef4444" /> },
  { id: 2, name: "Clients", icon: <GrGroup size={35} color="#3b82f6" /> },
  { id: 3, name: "Produits", icon: <PiPackage size={35} color="#eab308" /> },
  {
    id: 4,
    name: "Carnet desister",
    icon: <MdCreditCardOff size={35} color="#a855f7" />,
  },
  { id: 5, name: "Carnets", icon: <TbCardsFilled size={35} color="#A0DEFF" /> },
  {
    id: 6,
    name: "Nbre de désistement",
    icon: <RiShoppingCartLine size={35} color="#F7DCB9" />,
  },
  {
    id: 7,
    name: "Déversement du jour",
    icon: <GrMoney size={35} color="#42f59e" />,
  },
  {
    id: 8,
    name: "Carnet du jour",
    icon: <PiCardsThreeLight size={35} color="#03e3fc" />,
  },
  {
    id: 9,
    name: "Carnet total vendu",
    icon: <LuShoppingCart size={35} color="#ff7f50" />,
  },
  {
    id: 10,
    name: "Carnet total livré",
    icon: <BsCartCheck size={35} color="#9932cc" />,
  },
  {
    id: 11,
    name: "Carnet total non livré",
    icon: <MdOutlineRemoveShoppingCart size={35} color="#20b2aa" />,
  },
  {
    id: 12,
    name: "Client désistés",
    icon: <MdOutlineGroupOff size={35} color="#ffa500" />,
  },
];

function NumberCol() {
  moment.locale("fr");
  const [controleurLength, setControleurLength] = useState("");
  const [collectorLength, setCollectorLength] = useState("");
  const [clientLength, setClientLength] = useState("");
  const [productsLength, setProductsLength] = useState([]);
  const [carnetDesteLength, setCarnetDesisteLength] = useState([]);
  const [carnetLength, setCarnetLength] = useState([]);
  const [desistementLength, setDesistementLength] = useState([]);
  const [deverseGLength, setDeverseGLength] = useState("");
  const [carnetSellLength, setCarnetSellLength] = useState("");
  const [carnetTotalSellLength, setCarnetTotalSellLength] = useState("");
  const [carnetTotalLivreLength, setCarnetTotalLivreLength] = useState("");
  const [carnetTotalNonLivreLength, setCarnetTotalNonLivreLength] =
    useState("");
  const [clientsDesisterLength, setClientsDesisterLength] = useState("");
  const [deverseG, setDeverseG] = useState([]);

  // Modal
  const [modal, setModal] = useState(false);

  // Admin Get Controleur length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("admin_tk");
        const response = await axios.get(`${url}/v1/get/all/controleur`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          console.log(response);
          return setControleurLength(response.data?.controleur_length);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Admin Get Colllector length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("gestoken");
        const response = await axios.post(
          `${geSUrl}/api/auth/admin/agent_collecteur/list?all=true`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        if (response.status === 200) {
          setCollectorLength(response.data?.total);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  });

  // Admin Get Client length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("gestoken");
        const response = await axios.post(
          `${geSUrl}/api/auth/admin/client/list`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        if (response.status === 200) {
          setClientLength(response.data?.total);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Admin Get Products length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("admin_tk");
        const response = await axios.get(`${url}/v1/get/store`, {
          headers: {},
        });
        if (response.status === 200) {
          return setProductsLength(response.data?.products?.length);
        }
      };
      ctrLength();
    } catch (error) {
      if (error.response.status === 40) {
        console.log("not found");
      }
      console.log(error);
    }
  }, []);

  // Admin Get Carnet Desister length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("admin_tk");
        const response = await axios.get(
          `${url}/v1/admin/get/desistement/list`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        if (response.status === 200) {
          return setCarnetDesisteLength(response.data?.data);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Admin Get Carnet length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const response = await axios.get(`${url}/get/all/carnet`, {
          headers: {},
        });
        if (response.status === 200) {
          return setCarnetLength(response.data?.liste?.length);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Admin Get Desistement length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("admin_tk");
        const response = await axios.get(
          `${url}/v1/admin/get/desistement/list`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        if (response.status === 200) {
          return setDesistementLength(response.data?.data?.length);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Get Globale day deversement
  useEffect(() => {
    const fetchDeversementG = async () => {
      const token = sessionStorage.getItem("gestoken");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Config.GESCAPO_LINK}/api/auth/admin/statistique`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setDeverseGLength(response.data.totalDeversementJournalier);
          setCarnetSellLength(response.data.totalCarnetJour);

          setCarnetTotalSellLength(response.data.carnet);
          // setClientsDesisterLength(response.data.totalClientDesister);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchDeversementG();
  }, []);

  // Carnet Livre
  useEffect(() => {
    const fetchCarnetLivre = async () => {
      const token = sessionStorage.getItem("gestoken");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Config.GESCAPO_LINK}/api/auth/admin/v2/carnets/termine/livre`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setCarnetTotalLivreLength(response.data.total);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchCarnetLivre();
  }, []);

  // Carnet non livré
  useEffect(() => {
    const fetchCarnetNonLivre = async () => {
      const token = sessionStorage.getItem("gestoken");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Config.GESCAPO_LINK}/api/auth/admin/v2/carnets/termine/non-livre`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setCarnetTotalNonLivreLength(response.data.total);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchCarnetNonLivre();
  }, []);

  // Get deversement History
  const [date, setDate] = useState("");
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    fetchDeversementHist();
  }, [date]);

  const fetchDeversementHist = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/get/all/deversement/globale/history?query=${date}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setDeverseG(response.data.data);
        setNotFound(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setNotFound(true);
        }
        console.log(error);
      });
  };

  const render1 = useCallback(() => {
    return (
      <div className="h-[100vh] overflow-auto scrollbar-hide">
        <div className="mt-[65px] max-sm:pb-[5%] justify-start overflow-auto scrollbar-hide min-h-[300px] flex flex-wrap">
          {/* Statistique*/}
          {state.map(({ id, name, icon }) => (
            <button
              onClick={() => {
                id === 7 && setModal(true);
                fetchDeversementHist();
              }}
              key={id}
              className={
                id !== 7
                  ? "w-[15.5%] max-sm:w-full max-sm:h-[120px] cursor-auto flex flex-col p-3 m-2 max-sm:p-1 h-[130px] rounded-xl bg-[#ADADAD36]"
                  : "w-[15.5%] max-sm:w-full max-sm:h-[120px] flex flex-col p-3 m-2 max-sm:p-1 h-[130px] rounded-xl bg-[#ADADAD36]"
              }
            >
              {/* Header */}
              <div className="flex flex-row items-center">
                <div
                  className={
                    id === 0
                      ? "bg-[#22c55e24] p-2 rounded-full"
                      : id === 1
                        ? "bg-[#ef444424] p-2 rounded-full"
                        : id === 2
                          ? "bg-[#3b82f624] p-2 rounded-full"
                          : id === 3
                            ? "bg-[#eab30824] p-2 rounded-full"
                            : id === 4
                              ? "bg-[#a855f724] p-2 rounded-full"
                              : id === 5
                                ? "bg-[#A0DEFF24] p-2 rounded-full"
                                : id === 6
                                  ? "bg-[#F7DCB924] p-2 rounded-full"
                                  : id === 7
                                    ? "bg-[#42f59e24] p-2 rounded-full"
                                    : id === 8
                                      ? "bg-[#03e3fc24] p-2 rounded-full"
                                      : id === 9
                                        ? "bg-[#ff7f5024] p-2 rounded-full"
                                        : id === 10
                                          ? "bg-[#9932cc24] p-2 rounded-full"
                                          : id === 11
                                            ? "bg-[#20b2aa24] p-2 rounded-full"
                                            : id === 12 &&
                                              "bg-[#ffa50024] p-2 rounded-full"
                  }
                >
                  {icon}
                </div>
                <p className="text-white ml-3 font-bold text-base">{name}</p>
              </div>

              {/* Number */}
              <p
                className={
                  id === 0
                    ? "text-green-500 text-3xl font-bold m-3"
                    : id === 1
                      ? "text-red-500 text-3xl font-bold m-3"
                      : id === 2
                        ? "text-blue-500 text-3xl font-bold m-3"
                        : id === 3
                          ? "text-yellow-500 text-3xl font-bold m-3"
                          : id === 4
                            ? "text-purple-500 text-3xl font-bold m-3"
                            : id === 5
                              ? "text-[#A0DEFF] text-3xl font-bold m-3"
                              : id === 6
                                ? "text-[#F7DCB9] text-3xl font-bold m-3"
                                : id === 7
                                  ? "text-[#42f59e] text-3xl font-bold m-3"
                                  : id === 8
                                    ? "text-[#03e3fc] text-3xl font-bold m-3"
                                    : id === 9
                                      ? "text-[#ff7f50] text-3xl font-bold m-3"
                                      : id === 10
                                        ? "text-[#9932cc] text-3xl font-bold m-3"
                                        : id === 11
                                          ? "text-[#20b2aa] text-3xl font-bold m-3"
                                          : id === 12 &&
                                            "text-[#ffa500] text-3xl font-bold m-3"
                }
              >
                {id === 0
                  ? <p>{controleurLength}</p> || "--"
                  : id === 1
                    ? <p>{collectorLength}</p> || "--"
                    : id === 2
                      ? <p>{clientLength}</p> || "--"
                      : id === 3
                        ? <p>{productsLength}</p> || "--"
                        : id === 4
                          ? (
                              <p>
                                {carnetDesteLength?.reduce(
                                  (acc, current) => acc + current.total_carnet,
                                  0,
                                )}
                              </p>
                            ) || "--"
                          : id === 5
                            ? <p>{carnetLength}</p> || "--"
                            : id === 6
                              ? <p>{desistementLength}</p> || "--"
                              : id === 7
                                ? (
                                    <p>
                                      {deverseGLength.toLocaleString("fr-FR") +
                                        " " +
                                        "F"}
                                    </p>
                                  ) || "--"
                                : id === 8
                                  ? <p>{carnetSellLength}</p> || "--"
                                  : id === 9
                                    ? <p>{carnetTotalSellLength}</p> || "--"
                                    : id === 10
                                      ? <p>{carnetTotalLivreLength}</p> || "--"
                                      : id === 11
                                        ? <p>{carnetTotalNonLivreLength}</p> ||
                                          "--"
                                        : (id === 12 && <p>---</p>) || "--"}
              </p>
            </button>
          ))}
        </div>

        {/* Agence Listes */}
        <AgencesListe />
      </div>
    );
  }, [
    collectorLength,
    clientLength,
    productsLength,
    carnetDesteLength,
    carnetLength,
    desistementLength,
    deverseGLength,
    carnetSellLength,
  ]);

  const render = useCallback(() => {
    return (
      <>
        {notFound ? (
          <div>
            <p className="text-white text-center">Aucun resultat trouver !</p>
          </div>
        ) : (
          <div className="overflow-auto scrollbar-hide w-full h-[88%]">
            {[...deverseG].map((devers, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="flex flex-row items-start justify-between p-5 rounded-xl border-b-[1px solid #e2e2e2]"
                  >
                    <div className="flex flex-col items-start">
                      <p className="text-green-500 text-xl font-bold">
                        Montant :{" "}
                        {(
                          parseInt(devers.amount).toFixed(0) * 1
                        ).toLocaleString("fr-FR") +
                          " " +
                          "FCFA"}
                      </p>

                      <h2 className="text-white text-xs mt-2 font-bold">
                        Date : {moment(devers.date).calendar()}
                      </h2>
                    </div>

                    {/* Carnet */}
                    <div className="flex flex-col items-end">
                      {/* Carnet Vendue */}
                      <p className="text-white text-base">Carnet vendue jour</p>

                      <p className="text-[#03e3fc] text-xl font-bold">
                        {devers.carnet_vendue_jour == 0
                          ? "---"
                          : devers.carnet_vendue_jour}
                      </p>
                    </div>
                  </div>

                  {/* divider */}
                  <div className="w-full h-[1px] bg-white" />
                </>
              );
            })}
          </div>
        )}
      </>
    );
  }, [deverseG, notFound]);

  return (
    <>
      {/* Stastiques */}
      {render1()}

      {/* Deversement Globale History */}
      <div
        className={
          modal
            ? "w-[83.5%] max-sm:w-[98%] bg-[#ffffff14] absolute top-0 h-full flex justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#1c1c1c] rounded-xl absolute top-20 max-sm:top-5 px-5 w-[40%] max-sm:w-full h-[90vh] max-sm:mr5">
          {/* Header */}
          <div>
            {/* Title */}
            <h2 className="text-white text-center text-center text-xl mt-5 font-bold">
              Historique des déversements globals
            </h2>
            {/* Close Button */}
            <button
              onClick={() => setModal(false)}
              className="flex items-center justify-center absolute top-4 right-4 text-white font-medium"
            >
              <IoMdClose size={25} color="white" />
            </button>

            {/* Search By date */}
            <div className="flex items-center bg-[#ffffff24] justify-around p-3 rounded-xl">
              <h2 className="text-white">Recherche par date : </h2>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="bg-[transparent] text-white outline-none cursor-pointer"
              />
            </div>
          </div>

          {/* Body */}
          {render()}
        </div>
      </div>
    </>
  );
}

export default NumberCol;
