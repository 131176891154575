import React from "react";
import ClientHandlerComponent from "../../components/gestion_client/ClientManager";

function Gestion_client() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <ClientHandlerComponent />
    </div>
  );
}

export default Gestion_client;
