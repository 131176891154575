import React from "react";
import { CiUser } from "react-icons/ci";

function Info({ nom, prenom, numero, profession }) {
  return (
    <div className="mt-[15%] flex felx-row items-center">
      {/* Avatar */}
      <div>
        <CiUser size={70} color="white" />
      </div>

      <div>
        <p className="text-white font-bold">
          {nom} {prenom}
        </p>
        <p className="text-white">numero : {numero}</p>
        <p className="text-white">profession : {profession}</p>
      </div>
    </div>
  );
}

export default Info;
