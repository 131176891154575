import React from "react";
import Bonus from "../../comonents/bonus/BonusListesLgg";

export default function BonusLgg() {
  return (
    <div className="overflow-hidden h-screen">
      <Bonus />
    </div>
  );
}
