import React from "react";
import RapportList from "../../components/raport/RapportList";

function Raport() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <RapportList />
    </div>
  );
}

export default Raport;
