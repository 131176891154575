import React from "react";
import DesistementGroupeComponent from "../../components/desistement_groupe/DesistementGroupe";

function Litige() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <DesistementGroupeComponent />
    </div>
  );
}

export default Litige;
