import React from "react";
import DesistementList from "../../components/desistement/DesistementList";

function Desistement() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <DesistementList />
    </div>
  );
}

export default Desistement;
