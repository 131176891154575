import React, { useEffect, useState } from "react";
import Load from "../../../assets/rff.svg";
import Nothing from "../../../assets/rien.png";
import Config from "../../config/Links";
import { IoMdClose } from "react-icons/io";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import { IoLockOpenOutline } from "react-icons/io5";
import { HiOutlineUserPlus } from "react-icons/hi2";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { IoRefresh } from "react-icons/io5";
import { CiPhone } from "react-icons/ci";
import { RiUserSmileLine } from "react-icons/ri";
import { GiTakeMyMoney } from "react-icons/gi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "moment/locale/fr";
import qs from "qs";
import axios from "axios";
import { BsBuilding } from "react-icons/bs";

const menu = [
  { id: 0, title: "Liste des caissiers" },
  { id: 1, title: "Liste des vendeurs" },
];

// Generate
const avl = "gvendor@";
const genPasse = (length) => {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return avl + result;
};

export default function CarnetsListLgg() {
  moment.locale("fr");
  // State
  const [agenceListe, setAgenceListe] = useState([]);
  const [caissierListe, setCaissierListe] = useState([]);
  const [vendorListe, setVendorListe] = useState([]);
  const [caissierDeversementListe, setCaissierDeversementListe] = useState([]);
  const [vendorDeversementListe, setVendorDeversementListe] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [notFound1, setNotFound1] = useState(false);
  const [notFound2, setNotFound2] = useState(false);
  const [notFound3, setNotFound3] = useState(false);
  const [notFound4, setNotFound4] = useState(false);
  const [select, setSelect] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [caissierID, setCaissierID] = useState("");
  const [vendorID, setVendorID] = useState("");

  // Create Agence State
  const [agenceNom, setAgenceNom] = useState("");
  const [agenceVille, setAgenceVille] = useState("");
  const [agenceQuartier, setAgenceQuartier] = useState("");

  // Create Caissier State
  const [agenceID, setAgenceID] = useState("");
  const [agenceName, setAgenceName] = useState("");
  const [caissierNom, setCaissierNom] = useState("");
  const [caissierPrenom, setCaissierPrenom] = useState("");
  const [caissierPassword, setCaissierPassword] = useState(genPasse(3));
  const [caissierTel, setCaissierTel] = useState("");

  // Create Agence
  const createAgence = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      nom: agenceNom,
      ville: agenceVille,
      quartier: agenceQuartier,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/create/agence`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          setModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Get All Agence
  useEffect(() => {
    const fetcthAllAgence = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/all/agences`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setAgenceListe(response.data.agences);
          setNotFound(false);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setNotFound(true);
            setLoading(false);
          }
          console.log(error);
        });
    };
    fetcthAllAgence();
  }, []);

  // Create Caissier
  const createNewCaissier = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      agence_id: agenceID,
      nom_agence: agenceName,
      nom: caissierNom,
      prenom: caissierPrenom,
      mot_de_passe: caissierPassword,
      telephone: caissierTel,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/create/caissier`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          setModal2(false);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Get All Caissier bY Agence
  useEffect(() => {
    const fetchAllCaissier = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/all/caissiers/${agenceID}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setLoading1(false);
          setNotFound1(false);
          setCaissierListe(response.data.caissiers);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setNotFound1(true);
            setLoading(false);
          }

          console.log(error);
        });
    };
    fetchAllCaissier();
  }, [agenceID]);

  // Get All Vendeur
  useEffect(() => {
    const fetchAllVendeur = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/all/col/by/${agenceID}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setLoading2(false);
          setNotFound2(false);
          setVendorListe(response.data.collecteurs);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setLoading2(false);
            setNotFound2(true);
          }
          console.log(error);
        });
    };
    fetchAllVendeur();
  }, [agenceID]);

  // Get All caissier Deversement History
  useEffect(() => {
    getAllCaissierDeverseHistory();
  }, [caissierID]);

  const getAllCaissierDeverseHistory = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/get/all/caissier/deversements/history/${caissierID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setLoading3(false);
        setNotFound3(false);
        setCaissierDeversementListe(response.data.deversements);
      })
      .catch((error) => {
        if (error && error.response.status === 404) {
          setLoading3(false);
          setNotFound3(true);
        }
        console.log(error);
      });
  };

  // Get All vendor Deversement History
  useEffect(() => {
    getAllVendorDeverseHistory();
  }, [vendorID]);

  const getAllVendorDeverseHistory = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/get/all/vendeur/deversements/history/${vendorID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setLoading4(false);
        setNotFound4(false);
        setVendorDeversementListe(response.data.deversements);
      })
      .catch((error) => {
        if (error && error.response.status === 404) {
          setLoading4(false);
          setNotFound4(true);
        }
        console.log(error);
      });
  };

  return (
    <>
      <div className="py-[60px] w-full flex-col  max-sm:px-2">
        {/* Header */}
        <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
          {/* Add NEW Agence*/}
          <button
            onClick={() => setModal(true)}
            title="Ajouter"
            className=" bg-[#ADADAD36] hover:bg-slate-800 max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-2 max-sm:p-1 flex flex-row"
          >
            <BsBuilding size={20} color="white" />
            <p className="text-white ml-2 text-xs">Crée une agence</p>
          </button>
        </div>

        {/* Create Agence */}
        <div
          className={
            modal
              ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
              : "hidden"
          }
        >
          <div className="bg-slate-800 w-3/12 h-[45%] rounded-xl p-3">
            {/* Header */}
            <div className="flex justify-between flex flex-row items-center">
              <div></div>

              <div className="flex flex-col items-center">
                {/* IcO */}
                <HiOutlineBuildingOffice2 size={40} color="white" />
                {/* Title */}
                <p className="text-white font-bold text-xl">
                  Crée une nouvelle Agence
                </p>
              </div>

              <button
                onClick={() => setModal(false)}
                className="bg-gray-100 hover:bg-slate-600 p-2 right-1 top-1 rounded-full flex items-center justify-center"
              >
                <IoMdClose />
              </button>
            </div>

            {/* Body */}
            <div className="h-[50%] mt-5 flex flex-col justify-between px-5">
              {/* Agence name */}
              <div>
                {/* Label */}
                <div className="bg-white p-3 w-full rounded-xl">
                  <input
                    className="border-none outline-none w-full"
                    value={agenceNom}
                    onChange={(e) => setAgenceNom(e.target.value)}
                    placeholder="Nom de l'agence"
                  />
                </div>
              </div>

              {/* Agence Vile */}
              <div>
                {/* Label */}
                <div className="bg-white p-3 w-full rounded-xl">
                  <input
                    className="border-none outline-none w-full"
                    value={agenceVille}
                    onChange={(e) => setAgenceVille(e.target.value)}
                    placeholder="Ville"
                  />
                </div>
              </div>

              {/* Agence Quartier */}
              <div>
                {/* Label */}
                <div className="bg-white p-3 w-full rounded-xl">
                  <input
                    className="border-none outline-none w-full"
                    value={agenceQuartier}
                    onChange={(e) => setAgenceQuartier(e.target.value)}
                    placeholder="Quartier"
                  />
                </div>
              </div>
            </div>

            {/*  button */}
            <div className="flex w-full bottom-2 mt-8 items-center justify-center">
              <button
                disabled={
                  agenceNom.length === 0 ||
                  agenceVille.length === 0 ||
                  agenceQuartier.length === 0
                }
                onClick={() => createAgence()}
                className={
                  agenceNom.length === 0 ||
                  agenceVille.length === 0 ||
                  agenceQuartier.length === 0
                    ? "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl opacity-50"
                    : "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl"
                }
              >
                <p className="text-white">Creé une agence</p>
              </button>
            </div>
          </div>
        </div>

        {/* Create Caissier */}
        <div
          className={
            modal1
              ? "w-[84%] h-[100vh] bg-[#38373769] absolute z-10 top-0 flex items-center justify-center"
              : "hidden"
          }
        >
          <div className="bg-slate-800 w-3/12 h-[55%] z-10 rounded-xl p-3">
            {/* Header */}
            <div className="flex justify-between flex flex-row items-center">
              {/* IcO */}
              <div></div>

              <div className="flex flex-col items-center">
                <RiUserSmileLine size={40} color="white" />
                {/* Title */}
                <p className="text-white font-bold text-xl">Crée un caissier</p>
              </div>

              <button
                onClick={() => setModal1(false)}
                className="bg-gray-100 hover:bg-slate-600 p-2 right-1 top-1 rounded-full flex items-center justify-center"
              >
                <IoMdClose />
              </button>
            </div>

            {/* Body */}
            <div className="h-[50%] mt-5 flex flex-col justify-between px-5">
              {/* Caissier name */}
              <div>
                {/* Label */}
                <div className="bg-white p-3 w-full rounded-xl">
                  <input
                    className="border-none outline-none w-full"
                    value={caissierNom}
                    onChange={(e) => setCaissierNom(e.target.value)}
                    placeholder="Nom du caissier"
                  />
                </div>
              </div>

              {/* Caissier prenom */}
              <div>
                {/* Label */}
                <div className="bg-white p-3 w-full rounded-xl">
                  <input
                    className="border-none outline-none w-full"
                    value={caissierPrenom}
                    onChange={(e) => setCaissierPrenom(e.target.value)}
                    placeholder="Prenom du caissier"
                  />
                </div>
              </div>

              {/* PassWord */}
              <div>
                {/* Label */}
                <div className="bg-slate-900 hover:bg-slate-700 flex flex-row items-center justify-between p-2 w-full rounded-xl">
                  <p className="font-medium text-base text-white">
                    {caissierPassword}
                  </p>

                  {/* Generate Passe */}
                  <button
                    onClick={() => setCaissierPassword(genPasse(3))}
                    className="hover:bg-slate-400 w-10 h-10 p-2 rounded-full flex items-center justify-center"
                  >
                    <IoRefresh color="#fff" />
                  </button>
                </div>
              </div>

              {/* Caissier Telephone */}
              <div>
                {/* Label */}
                <div className="bg-white p-3 w-full rounded-xl">
                  <input
                    className="border-none outline-none w-full"
                    value={caissierTel}
                    maxLength={8}
                    onChange={(e) => {
                      const input = e.target.value;
                      const filteredInput = input.replace(/[^\d.]/g, "");
                      setCaissierTel(filteredInput);
                    }}
                    placeholder="Téléphone"
                  />
                </div>
              </div>
            </div>

            {/*  button */}
            <div className="flex w-full bottom-2 mt-8 items-center justify-center">
              <button
                disabled={
                  agenceName.length === 0 ||
                  caissierNom.length === 0 ||
                  caissierPrenom.length === 0 ||
                  caissierTel.length === 0
                }
                onClick={() => createNewCaissier()}
                className={
                  agenceName.length === 0 ||
                  caissierNom.length === 0 ||
                  caissierPrenom.length === 0 ||
                  caissierTel.length === 0
                    ? "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl opacity-50"
                    : "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl"
                }
              >
                <p className="text-white">Creé un caissier</p>
              </button>
            </div>
          </div>
        </div>

        {/* Agence List */}
        {loading ? (
          <div className="flex flex-col items-center justify-center">
            <img src={Load} className="w-12 h-12" />
          </div>
        ) : notFound ? (
          <div className="flex flex-col items-center justify-center">
            <img src={Nothing} className="w-32 h-32" />
            <p className="text-white">Aucune agence trouvé !</p>
          </div>
        ) : (
          <div className="flex flex-wrap">
            {agenceListe.map((agence, index) => (
              <button
                onClick={() => {
                  setModal2(true);
                  setAgenceID(agence.id);
                  setAgenceName(agence.nom);
                }}
                key={index}
                className="bg-[#ffffff14] flex flex-row rounded-xl hover:bg-slate-950 p-2 m-2 w-[24%] max-sm:w-full"
              >
                {/* IcO */}
                <HiOutlineBuildingOffice2 size={40} color="white" />

                {/* RIGRH */}
                <div className="flex flex-row w-full justify-between">
                  {/* Left */}
                  <div className="ml-3 flex flex-col justify-between">
                    <p className="text-red-500">{agence.nom}</p>

                    <div className="flex felx-row">
                      <HiOutlineLocationMarker size={30} color="white" />

                      <div className="flex flex-col">
                        <p className="text-green-500 font-bold text-base">
                          {agence.ville}
                        </p>

                        <p className="text-white"> {agence.quartier}</p>
                      </div>
                    </div>

                    <p className="text-white">
                      Date : {moment(agence.create_at).calendar()}
                    </p>
                  </div>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Agence Details */}
      <div
        className={
          modal2
            ? "w-[83%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-8/12 top-[8%] h-[90%] bg-cyan-950 rounded-xl p-2">
          {/* Title */}
          <div className="flex flex-row justify-center">
            <p className="font-bold text-lg text-white">
              Bienvenu dans l'agence
            </p>
            <p className="font-bold text-lg text-green-500 ml-2">
              ({agenceName})
            </p>
          </div>

          {/* Close Button */}
          <button
            onClick={() => setModal2(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body Agence Detail */}
          <div
            className={
              "w-full h-[auto] overflow-auto scrollbar-hide flex flex-col"
            }
          >
            {/* Navigation */}
            <div className="flex flex-row justify-between">
              {/* Create Caissier Botton */}
              <button
                onClick={() => setModal1(true)}
                className="bg-green-500 hover:bg-green-600 p-2 flex flex-row items-center rounded-xl"
              >
                <HiOutlineUserPlus size={25} color="white" />
                <p className="text-white">Creer un caissier</p>
              </button>

              {/* Detail Menu Navigation */}
              <div className="w-4/12 p-2 rounded-full flex felx-row items-center bg-slate-700">
                {menu.map((menu, index) => (
                  <button
                    onClick={() => setSelect(menu.id)}
                    key={index}
                    className={
                      menu.id === select
                        ? "bg-slate-500 p-2 rounded-full w-full text-white"
                        : "p-2 rounded-full w-full text-white"
                    }
                  >
                    {menu.title}
                  </button>
                ))}
              </div>

              {/* Others Fonction */}
              <div></div>
            </div>

            {/* Body */}
            {select === 0 && (
              <div className="w-full h-full flex items-center justify-center">
                {/* Caissier List */}
                {loading1 ? (
                  <div className="flex flex-col items-center justify-center">
                    <img src={Load} className="w-12 h-12" />
                  </div>
                ) : notFound1 ? (
                  <div className="flex flex-col items-center justify-center">
                    <img src={Nothing} className="w-32 h-32" />
                    <p className="text-white">Aucune caissier trouvé !</p>
                  </div>
                ) : (
                  <div className="flex w-full fle flex-wrap">
                    {caissierListe.map((caisse, index) => (
                      <button
                        onClick={() => {
                          setCaissierID(caisse.id);
                          getAllCaissierDeverseHistory();
                          setModal3(true);
                        }}
                        key={index}
                        className="bg-[#ffffff14] flex flex-row rounded-xl hover:bg-slate-950 p-2 m-2 w-[23.3%] max-sm:w-full"
                      >
                        <div>
                          {/* Left */}
                          <RiUserSmileLine color="white" size={30} />

                          {/* Right */}
                          <div>
                            {/* Name */}
                            <div className="flex items-center ">
                              <p className="text-white text-base front-bold">
                                {caisse.nom}
                              </p>

                              <p className="text-white text-base front-bold ml-2">
                                {caisse.prenom.length > 8
                                  ? caisse.prenom.slice(0, 8) + "..."
                                  : caisse.prenom}
                              </p>
                            </div>

                            {/* <p>{caisse.mot_de_passe}</p> */}

                            {/* Phone */}
                            <div className="flex items-center">
                              <CiPhone size={20} color="white" />
                              <p className="ml-2 text-white">
                                (228) {caisse.telephone}
                              </p>
                            </div>

                            {/* Secret */}
                            <div className="flex items-center">
                              <IoLockOpenOutline size={20} color="white" />
                              <p className="ml-2 text-white">
                                {caisse.code_secret}
                              </p>
                            </div>

                            {/* Deversement */}
                            <div className="flex items-center">
                              <GiTakeMyMoney size={20} color="white" />
                              <p className="ml-2 text-green-500 text-base">
                                {(
                                  parseFloat(
                                    caisse.deversement_globale,
                                  ).toFixed(0) * 1
                                ).toLocaleString() +
                                  " " +
                                  "F"}
                              </p>
                            </div>

                            {/* Date */}
                            <div className="flex items-center">
                              <MdOutlineCalendarMonth size={20} color="white" />
                              <p className="ml-2 text-white">
                                {moment(caisse.created_at).calendar()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            )}

            {select === 1 && (
              <div className="w-full h-full flex items-center justify-center">
                {/* Caissier List */}
                {loading2 ? (
                  <div className="flex flex-col items-center justify-center">
                    <img src={Load} className="w-12 h-12" />
                  </div>
                ) : notFound2 ? (
                  <div className="flex flex-col items-center justify-center">
                    <img src={Nothing} className="w-32 h-32" />
                    <p className="text-white">Aucune vendeur trouvé !</p>
                  </div>
                ) : (
                  <div className="flex w-full fle flex-wrap">
                    {vendorListe.map((vendeur, index) => (
                      <button
                        onClick={() => {
                          getAllVendorDeverseHistory();
                          setModal4(true);
                          setVendorID(vendeur.id);
                        }}
                        key={index}
                        className="bg-[#ffffff14] flex flex-row rounded-xl hover:bg-slate-950 p-2 m-2 w-[23.3%] max-sm:w-full"
                      >
                        <div>
                          {/* Left */}
                          <RiUserSmileLine color="white" size={30} />

                          {/* Right */}
                          <div>
                            {/* Name */}
                            <p className="text-white text-base front-bold ml-2">
                              {vendeur.nom_utilisateur.length > 20
                                ? vendeur.nom_utilisateur.slice(0, 20) + "..."
                                : vendeur.nom_utilisateur}
                            </p>

                            {/* <p>{caisse.mot_de_passe}</p> */}

                            {/* Phone */}
                            <div className="flex items-center">
                              <CiPhone size={20} color="white" />
                              <p className="ml-2 text-white">
                                (228) {vendeur.telephone}
                              </p>
                            </div>

                            {/* Secret */}
                            <div className="flex items-center">
                              <IoLockOpenOutline size={20} color="white" />
                              <p className="ml-2 text-white">
                                {vendeur.code_secret}
                              </p>
                            </div>

                            <div className="flex items-center">
                              <GiTakeMyMoney size={20} color="white" />
                              <p className="ml-2 text-green-500 text-base">
                                {(
                                  parseFloat(vendeur.total_deversement).toFixed(
                                    0,
                                  ) * 1
                                ).toLocaleString() +
                                  " " +
                                  "F"}
                              </p>
                            </div>

                            {/* Date */}
                            <div className="flex items-center">
                              <MdOutlineCalendarMonth size={20} color="white" />
                              <p className="ml-2 text-white">
                                {moment(vendeur.created_at).calendar()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Caissier Deverse History Modal */}
      <div
        className={
          modal3
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-5/12 top-[10%] h-[85%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal3(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              {/* Header */}
              <p className="text-white text-lg font-semibold text-center">
                Historique des déversements (Caissier)
              </p>

              {/* Body */}
              {loading3 ? (
                <div className="flex flex-col items-center justify-center">
                  <img src={Load} className="w-12 h-12" />
                </div>
              ) : notFound3 ? (
                <div className="flex flex-col items-center justify-center">
                  <img src={Nothing} className="w-32 h-32" />
                  <p className="text-white">Aucun déversement trouvé!</p>
                </div>
              ) : (
                <div className="overflow-auto scrollbar-hide h-[85vh]">
                  {caissierDeversementListe.map((deversements, index) => (
                    <div
                      className="bg-slate-900 p-3 my-3 rounded-xl"
                      key={index}
                    >
                      {/* Receiver Name */}
                      <div className="flex items-center">
                        <RiUserSmileLine color="white" size={20} />

                        <div className="flex items-center">
                          <p className="text-white font-medium">
                            {deversements.caissier_name}
                          </p>
                          <p className="text-white font-medium">
                            {deversements.caissier_name}
                          </p>
                        </div>
                      </div>

                      {/* Deversement Amount */}
                      <div className="flex items-center">
                        <GiTakeMyMoney size={20} color="white" />
                        <p className="text-white text-base ml-2">
                          {(
                            parseFloat(deversements.somme_deverser).toFixed(0) *
                            1
                          ).toLocaleString("fr-FR") +
                            " " +
                            "F"}
                        </p>
                      </div>

                      <p className="text-white text-base">
                        {moment(deversements.created_at).calendar()}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Vendor Deverse History Modal */}
      <div
        className={
          modal4
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-5/12 top-[10%] h-[85%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal4(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              {/* Header */}
              <p className="text-white text-lg font-semibold text-center">
                Historique des déversements récupéré (Vendeur)
              </p>

              {/* Body */}
              {loading4 ? (
                <div className="flex flex-col items-center justify-center">
                  <img src={Load} className="w-12 h-12" />
                </div>
              ) : notFound4 ? (
                <div className="flex flex-col items-center justify-center">
                  <img src={Nothing} className="w-32 h-32" />
                  <p className="text-white">Aucun déversement trouvé!</p>
                </div>
              ) : (
                <div className="overflow-auto scrollbar-hide h-[85vh]">
                  {vendorDeversementListe.map((deversements, index) => (
                    <div
                      className="bg-slate-900 p-3 my-3 rounded-xl"
                      key={index}
                    >
                      {/* Receiver Name */}
                      <div className="flex items-center">
                        <RiUserSmileLine color="white" size={20} />

                        <div className="flex items-center">
                          <p className="text-white font-medium">
                            {deversements.caissier_name}
                          </p>
                          <p className="text-white font-medium">
                            {deversements.caissier_name}
                          </p>
                        </div>
                      </div>

                      {/* Deversement Amount */}
                      <div className="flex items-center">
                        <GiTakeMyMoney size={20} color="white" />
                        <p className="text-white text-base ml-2">
                          {(
                            parseFloat(deversements.somme_deverser).toFixed(0) *
                            1
                          ).toLocaleString("fr-FR") +
                            " " +
                            "F"}
                        </p>
                      </div>

                      <p className="text-white text-base">
                        {moment(deversements.created_at).calendar()}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
