import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import Config from "../../config/Links";
import { MdClose } from "react-icons/md";
import Load from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotCarnet from "../../assets/svg/no_carnet.png";
import qs from "qs";
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";
import { useNavigate } from "react-router-dom";

export default function CreateStock() {
  const navigation = useNavigate();
  const { id, name } = useParams();

  moment.locale("fr");
  const [agenceData, setAgenceData] = useState([]);
  const [carnetListe, setCarnetListe] = useState([]);
  const [getAgenceCarnetListe, setGetAgenceCarnetListe] = useState([]);
  const [agenceName, setAgenceName] = useState("");

  const [carnetSearch, setCarnetSearch] = useState("");
  const [carnetSelect, setCarnetSelect] = useState([]);

  // Loading State
  const [loading0, setLoading0] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loading3, setLoading3] = useState(false);

  // NotFound State
  const [notFound0, setNotFound0] = useState(false);

  // Agence State
  const [agenceNameC, setAgenceNameC] = useState("");
  useEffect(() => {
    setAgenceNameC(name);
  }, [name]);
  const [carnet_idC, setCarnet_idC] = useState([]);
  const [carnet_nameC, setCarnet_nameC] = useState([]);
  const [quantiteC, setQuantiteC] = useState([]);

  // Fetch All Agence Liste
  const fetchAgence = React.useCallback(async () => {
    const gesToken = sessionStorage.getItem("gestoken");
    try {
      const response = await axios.post(
        `${Config.GESCAPO_LINK}/api/auth/admin/agence/list?search=${agenceName}`,
        null,
        { headers: { Authorization: `Bearer ${gesToken}` } },
      );
      setAgenceData(response.data.data);
    } catch (error) {
      console.error("Error fetching agence data:", error);
    } finally {
      setLoading(false);
    }
  }, [agenceName, id]);

  useEffect(() => {
    fetchAgence();
  }, [fetchAgence]);

  useEffect(() => {
    const getAgenceListe = async () => {
      const token = sessionStorage.getItem("gestoken");
      try {
        const response = await axios.post(
          `${Config.GESCAPO_LINK}/api/auth/admin/carnet/list?search=${carnetSearch}&all=true`,
          null,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        setCarnetListe(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getAgenceListe();
  }, [carnetSearch, id]);

  // Create New Stocks
  const createNewStocks = async () => {
    const token = sessionStorage.getItem("admin_tk");

    // Vérifiez si les données sont définies
    if (!Array.isArray(carnet_idC) || carnet_idC.length === 0) {
      toast.error("Les données des carnets sont manquantes.");
      return;
    }
    // Créez des produits individuellement
    for (let i = 0; i < carnet_idC.length; i++) {
      const carnet_id = carnet_idC[i];
      const carnet_name = carnet_nameC[i];
      const quantite = quantiteC[i];

      const data = qs.stringify({
        agence_name: agenceNameC,
        carnet_id,
        carnet_name,
        quantite,
      });

      const config = {
        method: "post",
        url: `${Config.G_PERFORM_LINK}/v1/admin/create/stock/${id}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data,
      };

      try {
        await axios.request(config);
        toast.success(`Produit ${carnet_name} créé avec succès.`);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } catch (error) {
        setLoading3(false);
        toast.error(
          error.response?.data?.message ||
            `Une erreur est survenue pour le produit ${carnet_name}`,
        );
      }
    }
  };

  // Get Info
  useEffect(() => {
    if (id) {
      getCarnetsInfo(id);
    }
  }, [id]);

  const getCarnetsInfo = async (index) => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/admin/get/agence/stock/info/${index}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.request(config);
      if (response.data && response.data.data) {
        setNotFound0(false);
        setLoading0(false);
        setGetAgenceCarnetListe(response.data.data);
      } else {
        console.warn("Données invalides reçues :", response.data);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setLoading0(false);
        setNotFound0(true);
      }
      console.error("Erreur lors de la récupération des carnets :", error);
    }
  };

  return (
    <>
      <div className="flex flex-col  items-center w-full px-0">
        <div className="w-11/12 h-screen flex flex-row items-center bg-cyan-950 rounded-xl p-4">
          {/* Search */}
          <div>
            {/* Back Button */}
            <div className="w-[100px] rounded-xl absolute left-[290px] max-sm:w-[auto] p-2 flex flex-row items-center">
              <button
                className="bg-[#ffffff24] p-4 rounded-full"
                onClick={() => navigation(-1)}
              >
                <IoMdArrowRoundBack size={20} color="white" />
              </button>
            </div>

            {/* Create Stock Button */}
            <div
              title="Recherche"
              className="bg-[#ffffff14] w-[420px] rounded-xl absolute top-2 max-sm:w-[auto] p-2 flex flex-row items-center"
            >
              <CiSearch size={25} color="white" />
              <input
                type="search"
                selec
                placeholder="Recherche de carnet"
                className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
                value={carnetSearch}
                onChange={(e) => setCarnetSearch(e.target.value)}
              />
            </div>
          </div>

          {/* Carnet Liste */}
          <div className="w-5/12 h-[95%] mt-5 overflow-auto scrollbar-hide flex flex-col">
            {/* Select Carnets */}
            {[...carnetListe]?.map((carnet, index) => {
              const checkCarnetAvailable = getAgenceCarnetListe.find(
                (carnetAv) => carnetAv.carnet_id === carnet.id,
              );
              return (
                <div>
                  <button
                    disabled={checkCarnetAvailable}
                    onClick={() => {
                      // Get Ids Lliste
                      setCarnet_idC((prevIds) => [...prevIds, carnet.id]);

                      // Get Libele Liste
                      setCarnet_nameC((prevNames) => [
                        ...prevNames,
                        carnet.libelle,
                      ]);

                      // Verify if already selected
                      if (!carnetSelect.includes(carnet)) {
                        setCarnetSelect((prevCarnet) => [
                          ...prevCarnet,
                          carnet,
                        ]);
                      }
                    }}
                    key={index}
                    className={
                      carnetSelect.includes(carnet)
                        ? "flex justify-start my-2 items-center bg-blue-800 rounded-xl p-2"
                        : checkCarnetAvailable
                          ? "flex justify-start my-2 items-center opacity-40 rounded-xl p-2"
                          : "flex justify-start my-2 items-center hover:bg-blue-800 rounded-xl p-2"
                    }
                  >
                    {carnetSelect.includes(carnet) ? (
                      <IoIosCheckmarkCircleOutline size={25} color="red" />
                    ) : (
                      ""
                    )}

                    <p className="text-white text-base text-left">
                      {carnet.libelle}
                    </p>
                  </button>

                  {/* Divider 1 */}
                  <div className="w-[90%] h-[1px] bg-gray-400" />
                </div>
              );
            })}
          </div>

          {/* divider */}
          <div className="h-[90%] w-[3px] mx-3 mt-10 bg-gray-500 rounded-full" />

          {/* Apercus carnet Select */}
          <div className="w-8/12 flex flex-row justify-start items-start overflow-auto scrollbar-hide min-h-[auto] max-h-[90%] flex-wrap">
            {/* Agence Name */}
            <div className="absolute top-[20px] flex justify-center w-6/12">
              <p className="text-white text-lg font-bold ">{name}</p>
            </div>

            {carnetSelect.length > 0 ? (
              [...carnetSelect]?.map((carnet, index) => (
                <div className="w-[30%] h-[170px] bg-[#ffffff14] rounded-xl shadow-md m-2 p-2">
                  {/* Remove carnet && Quantity */}
                  <div className="w-full flex justify-end">
                    {/* Quantity */}
                    <div className="p-2 bg-white rounded-xl">
                      <input
                        className="w-full border-none outline-none"
                        maxLength={3}
                        placeholder="Quantité ?"
                        defaultValue={null || quantiteC[index]}
                        onChange={(e) => {
                          const input = e.target.value;
                          const filteredInput = input.replace(/[^\d]/g, "");
                          setQuantiteC((prevQuantities) => {
                            const newQuantities = [...prevQuantities];
                            newQuantities[index] = filteredInput;
                            return newQuantities;
                          });
                        }}
                      />
                    </div>

                    {/* Remove button */}
                    <button
                      title="Retirer le carnet"
                      className="bg-[#ffffff14] rounded-full hover:bg-slate-900 shadow-md m-1 p-2"
                      onClick={() =>
                        setCarnetSelect((prevCarnet) =>
                          prevCarnet.filter((item) => item !== carnet),
                        )
                      }
                    >
                      <MdClose size={15} color="white" />
                    </button>
                  </div>

                  {/* body */}
                  <p key={index} className="text-white mx-1">
                    {carnet.libelle}
                  </p>
                </div>
              ))
            ) : (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <img src={NotCarnet} className="w-[100px] h-[100px]" />
                <p className="text-white mt-3 text-center">
                  Aucun carnet sélectionné
                </p>
              </div>
            )}

            {/* Create Button */}
            <div className="absolute bottom-5 flex justify-center w-6/12">
              {loading3 ? (
                <div className="flex justify-center items-center">
                  <img src={Load} className="w-10 h-10" />
                </div>
              ) : (
                <button
                  disabled={
                    carnetSelect.length === 0 ||
                    quantiteC.length === 0 ||
                    quantiteC.some(
                      (q) => q === null || q === "" || parseFloat(q) <= 0,
                    )
                  }
                  className={
                    carnetSelect.length === 0 ||
                    quantiteC.length === 0 ||
                    quantiteC.some(
                      (q) => q === null || q === "" || parseFloat(q) <= 0,
                    )
                      ? "bg-gray-500 p-2 rounded-xl items-center justify-center opacity-50"
                      : "bg-blue-500 p-2 rounded-xl hover:bg-blue-800 items-center justify-center"
                  }
                  onClick={() => {
                    setLoading3(true);
                    createNewStocks();
                  }}
                >
                  <p className="text-white text-base">Generer vos stock</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Feedback */}
      <ToastContainer />
    </>
  );
}
