import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Loading from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import { TbUserHexagon } from "react-icons/tb";
import Config from "../../config/Links";
import { CiSearch } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import moment from "moment";
import "moment/locale/fr";

function ClientDeleiver({ col_id }) {
  const navigation = useNavigate();
  moment.locale("fr");
  const [allClient, setAllClient] = useState([]);
  const [clientDeliver, setClientDeliver] = useState([]);
  const [clientFilter, setClientFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const url = Config.GESCAPO_LINK;
  const token = sessionStorage.getItem("gestoken");

  // Request
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/api/auth/admin/agent_collecteur/client/${col_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchControleur = async () => {
      try {
        const response = await axios.request(config);
        setAllClient(response.data.data);
        console.log("Clients", response.data.data);
        console.log(col_id);
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchControleur();
  }, [search]);

  // Client Deliver
  let config2 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/auth/admin/v2/carnets/termine/non-livre`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const delvier = async () => {
      await axios
        .request(config2)
        .then((response) => {
          setClientDeliver(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    delvier();
  }, []);

  useEffect(() => {
    if (allClient && clientDeliver.length > 0) {
      const filteredClients = clientDeliver.filter(
        (client) =>
          client.id_client === allClient.id && client.delivered === true,
      );
      setClientFilter(filteredClients);
      console.log("Clients livrés", filteredClients);
    }
  }, [clientDeliver, allClient]);

  const lists = useMemo(() => {
    return (
      <div className="flex flex-row flex-wrap max-sm:pb-20 h-[70vh] max-sm:grid max-sm:grid-cols-1 overflow-auto scrollbar-hide">
        {loading ? (
          <div className="w-full h-[50vh] mb-[20%] max-sm:mb-[30%] flex flex-grow justify-center items-center">
            <img src={Loading} className="w-20 h-20" />
          </div>
        ) : clientFilter.length > 0 ? (
          clientFilter.map(
            ({ id, nom, prenom, numero, nbr_carnet, created_at }) => {
              return (
                <Link to={`/dashboard/colecteur/client/info/${id}`}>
                  <div
                    title="Controleur"
                    key={id}
                    className="bg-slate-700 w-[300px] max-sm:w-full my-2 h-[130px] rounded-md justify-between px-2 mx-2 flex flex-row hover:bg-[#282828] cursor-pointer"
                  >
                    <div>
                      <TbUserHexagon size={50} color="#fff" />
                    </div>

                    <div className="flex flex-col justify-around">
                      <p className="text-white font-semibold">
                        {nom}{" "}
                        {prenom.length > 10
                          ? `${prenom.slice(0, 10)}...`
                          : prenom}
                      </p>
                      <p className="text-white"> (+228) {numero}</p>
                      <p className="text-white">Nbre Carnet : {nbr_carnet}</p>
                      <p className="text-white">
                        Date : {moment(created_at).calendar()}
                      </p>
                    </div>

                    {/* Tools */}
                    <div className="flex flex-col justify-between py-2"></div>
                  </div>
                </Link>
              );
            },
          )
        ) : (
          <div className="flex w-full justify-center items-center h-[82vh]">
            <div className="flex flex-col justify-center items-center max-sm:mb-[50%]">
              <img src={Nothing} className="w-20 h-20 mb-4" />
              <p className="font-bold text-white mb-5">
                Aucun Client trouver !
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }, [clientFilter, search]);

  return (
    <>
      <div className=" w-full flex-col  max-sm:px-2">
        {/* Search Bar */}
        <div className="w-full z-20 bg-black mt-[20px] py-1 flex flex-row items-center justify-between px-3 max-sm:px-0">
          {/* Back */}
          <div className="flex flex-row items-center">
            <button
              onClick={() => navigation(-1)}
              className="bg-[#ADADAD36] p-3 rounded-md hover:bg-slate-500"
            >
              <IoArrowBack color="white" />
            </button>

            <p className="font-bold text-2xl text-white ml-2">Clients</p>
          </div>

          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Recheche client"
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {lists}
      </div>
    </>
  );
}

export default ClientDeleiver;
