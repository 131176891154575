import React, { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../config/Links";
import Loading from "../../assets/loading.svg";
import { ToastContainer, toast } from "react-toastify";
import { TbCardsFilled } from "react-icons/tb";
import "react-toastify/dist/ReactToastify.css";
import { LiaCartPlusSolid } from "react-icons/lia";
import { RiMoneyEuroBoxFill } from "react-icons/ri";
import { CiImageOn } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

function CreateCarnet() {
  const navigation = useNavigate();
  const url = Config.G_PERFORM_LINK;
  const token = sessionStorage.getItem("admin_tk");

  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [carnetName, setCarnetName] = useState("");
  const [mise, setMise] = useState("");
  const [loading1, setLoading1] = useState(false);

  // Send Request
  const form = new FormData();
  form.append("carnetName", carnetName);
  form.append("carnet", imageFile);
  form.append("mise", mise);

  let options = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/admin/add/new/carnet`,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/form-data",
    },
    data: form,
  };

  const createCarnet = async () => {
    await axios
      .request(options)
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          setTimeout(() => {
            navigation(-1);
          }, 1500);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setLoading1(false);
        } else if (error.response.status === 500) {
          toast.error(error.response.data.message);
          setLoading1(false);
        } else {
          toast.error("Erreur de creation de produit !");
          console.log(error);
          setLoading1(false);
        }
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  return (
    <>
      <div className="flex justify-center items-center mt-[70px] px-[32%] max-sm:px-0">
        <div className="flex w-full h-[90vh] bg-[#ADADAD36] rounded-xl max-sm:bg-transparent flex-col">
          {/* Header */}
          <div className="flex flex-row items-center w-full justify-center">
            {/* Add New Button */}
            <div className="flex flex-row h-20 items-center px-3">
              <TbCardsFilled size={30} color="#fff" />
              <p className="text-white text-xl font-bold ml-3">
                Crée un nouveau carnet
              </p>
            </div>
          </div>

          {/* Forms */}
          <div className="px-[20%] max-sm:px-[10%]">
            {/* Img fetch uri */}
            <div className="mt-5">
              {imagePreview === "" ? (
                <label
                  htmlFor="img"
                  className="cursor-pointer border-[1px] h-[200px] flex flex-col items-center justify-center border-white m-2 rounded-xl border-dashed"
                >
                  <div className="flex flex-row items-center">
                    <input
                      type="file"
                      accept="image/*"
                      id="img"
                      name="image_url"
                      className="hidden w-0 h-0"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setImageFile(file);
                        setImagePreview(URL.createObjectURL(file));
                      }}
                    />

                    <LiaCartPlusSolid size={60} color="#fff" />
                    <p className="text-white mx-2">+</p>
                    <CiImageOn size={60} color="#fff" />
                  </div>
                  <p className="text-white my-6">Ajouter l'image du produit</p>
                </label>
              ) : (
                <img
                  src={imagePreview}
                  className="w-full h-full"
                  alt="Aperçu de l'image"
                />
              )}
            </div>

            {/* Product Name */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center mt-12 p-3 rounded-lg mb-4">
              <TbCardsFilled size={30} color="#fff" />
              <input
                required
                type="text"
                placeholder="Nom du carnet"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={carnetName}
                onChange={(e) => setCarnetName(e.target.value)}
              />
            </div>

            {/*  Price */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <RiMoneyEuroBoxFill size={30} color="#fff" />
              <input
                required
                type="text"
                placeholder="Mise"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={mise}
                onChange={(e) => setMise(e.target.value)}
              />

              <p className="text-white">FCFA</p>
            </div>

            {/* Creat Controleur button */}
            <div className="flex justify-center items-center mt-10">
              {loading1 ? (
                <img src={Loading} className="w-10 h-10" />
              ) : (
                <button
                  onClick={() => {
                    setLoading1(!loading1);
                    createCarnet();
                  }}
                  disabled={
                    imageFile === "" || carnetName === "" || mise === ""
                  }
                  className={
                    imageFile === "" || carnetName === "" || mise === ""
                      ? "bg-blue-900 p-3 w-[300px] rounded-xl opacity-60"
                      : "bg-blue-900 p-3 w-[300px] rounded-xl hover:bg-slate-900"
                  }
                >
                  <p className="text-white">Crée un Carnet</p>
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Toastify */}
        <ToastContainer />
      </div>
    </>
  );
}

export default CreateCarnet;
