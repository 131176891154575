import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Loading from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import { LiaCartPlusSolid } from "react-icons/lia";
import { IoMdClose } from "react-icons/io";
import { FaPencilAlt } from "react-icons/fa";
import Config from "../../config/Links";
import { CiSearch } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "moment/locale/fr";
import { Link } from "react-router-dom";
import qs from "qs";

function StoreProductList() {
  moment.locale("fr");
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const url = Config.G_PERFORM_LINK;
  const token = sessionStorage.getItem("admin_tk");
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [productID, setProductID] = useState("");
  const [productName, setProductName] = useState("");
  const [productSellPrice, setProductSellPrice] = useState("");

  // Request
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/search/product?query=${search}`,
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.request(config);
        setProduct(response.data.products);
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [search]);

  // Edite
  let data = qs.stringify({
    product_name: productName,
    sell_price: productSellPrice,
  });

  let config0 = {
    method: "patch",
    maxBodyLength: Infinity,
    url: `${url}/admin/update/desistement/product/${productID}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  const fetchProduct = async () => {
    try {
      const response = await axios.request(config);
      setProduct(response.data.products);
    } catch (error) {
      console.log(error);
    }
  };

  const updateInfoP = async () => {
    await axios
      .request(config0)
      .then((response) => {
        toast.success(response.data.message);
        fetchProduct();
        setTimeout(() => {
          setShowModal(!showModal);
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Delete Product
  let config2 = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/delete/product/${productID}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const deleteProduct = async () => {
    await axios
      .request(config2)
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          setShowModal(!showModal);
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  const lists = useMemo(() => {
    return (
      <div className="flex flex-row flex-wrap max-h-[85vh] overflow-auto scrollbar-hide">
        {loading ? (
          <div className="w-full h-[70vh] mb-[10%] max-sm:mb-[30%] flex flex-grow justify-center items-center">
            <img src={Loading} className="w-20 h-20" />
          </div>
        ) : product?.length > 0 ? (
          product?.map((item) => (
            <button key={item.id} className="p-2">
              {/* Product IMg */}
              <div
                className="bg-cover flex flex-col p-2 justify-between bg-center w-[185px] h-[160px] rounded-md"
                style={{ backgroundImage: `url(${item.image_url})` }}
              >
                {/* Edite */}
                <button
                  onClick={() => {
                    setProductID(item.id);
                    setProductName(item.product_name);
                    setProductSellPrice(item.sell_price);
                    setShowModal1(!showModal1);
                  }}
                  title="Modifier"
                  className="flex flex-wrap bg-slate-500 p-2 rounded-full w-[30px] h-[30px]"
                >
                  <FaPencilAlt color="white" />
                </button>

                {/* Remove product */}
                <button
                  onClick={() => {
                    setProductID(item.id);
                    setShowModal(!showModal);
                  }}
                  title="Supprimer"
                  className="flex flex-wrap bg-red-500 p-2 rounded-full w-[30px] h-[30px]"
                >
                  <RiDeleteBin6Line color="white" />
                </button>
              </div>

              <p className="text-white">{item.product_name}</p>

              <div>
                <div className="flex flex-row items">
                  <p className="text-white"> Prix de vente :</p>
                  <p className="text-red-500 ml-2 font-bold">
                    {item.sell_price} F
                  </p>
                </div>

                <div className="flex flex-row items">
                  <p className="text-white"> Marge :</p>
                  <p className="text-green-400 ml-2"> {item.margin} F</p>
                </div>
              </div>
            </button>
          ))
        ) : (
          <div className="flex w-full justify-center items-center h-[75vh]">
            <div className="flex flex-col justify-center items-center mb-[10%] max-sm:mb-[50%]">
              <img src={Nothing} className="w-20 h-20 mb-4" />
              <p className="font-bold text-white mb-5">
                Aucun produit trouver !
              </p>

              {/* Add NEW Controleur */}
              <Link to="/dashboard/create/product">
                <button className="bg-[#ADADAD36] hover:text-slate-700 rounded-full items-center p-2 flex flex-row">
                  <LiaCartPlusSolid size={20} color="white" />
                  <p className="text-white ml-2">Ajouter un produit</p>
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }, [product]);

  return (
    <>
      <div className="py-[60px] w-full flex-col  max-sm:px-2">
        {/* Search Bar */}
        <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
          {/* Add NEW Product */}
          <Link to="/dashboard/create/product">
            <button
              title="Ajouter"
              className=" bg-[#ADADAD36] max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-2 max-sm:p-1 flex flex-row"
            >
              <LiaCartPlusSolid size={20} color="white" />
              <p className="text-white ml-2 text-xs">Ajouter un produit</p>
            </button>
          </Link>

          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Recheche de produit"
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {lists}
      </div>

      {/* Edite pop */}
      <div
        className={
          !showModal1
            ? "hidden"
            : "flex w-9/12 absolute top-0 bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal1
              ? "hidden"
              : "absolute w-[310px] h-[380px] text-center flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#28f10029]">
              <FaPencilAlt size={25} color="green" />
            </div>
          </div>

          {/* Title */}
          <div className="flex flex-row justify-center">
            {/* Title */}

            <p className="text-white font-bold text-xl mt-[70px] text-center">
              Modifier le produit
            </p>

            <button
              onClick={() => setShowModal1(!showModal1)}
              className="bg-[#ADADAD36] absolute right-2 top-2 rounded-full flex justify-center items-center"
            >
              <IoMdClose color="white" size={25} />
            </button>
          </div>

          {/* Forms */}
          <div className="h-[180px] flex flex-col mt-2">
            {/* Product Name */}
            <div>
              <input
                placeholder="Nom"
                className="p-3 bg-[#ADADAD36] border-none outline-none text-white"
                defaultValue={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>

            {/* Sell price */}
            <div>
              <input
                placeholder="Product Price"
                className="p-3 bg-[#ADADAD36] border-none outline-none text-white mt-3"
                defaultValue={productSellPrice}
                onChange={(e) => setProductSellPrice(e.target.value)}
              />
            </div>
          </div>

          {/* Button */}
          <div className="px-[10%] w-full flex justify-center mb-5">
            {/* uPDATE PRODUCT  */}
            <button
              disabled={
                productName.length === 0 || productSellPrice.length === 0
              }
              onClick={() => {
                updateInfoP();
              }}
              className={
                productName.length === 0 || productSellPrice.length === 0
                  ? "w-full h-10 bg-green-600 rounded-md hover:bg-slate-600 mx-2 opacity-40"
                  : "w-full h-10 bg-green-600 rounded-md hover:bg-slate-600 mx-2"
              }
            >
              <p className="text-white font-medium text-base">Mise à jour</p>
            </button>
          </div>
        </div>
      </div>

      {/* Delete Confirm Pop */}
      <div
        className={
          !showModal
            ? "hidden"
            : "flex w-9/12 absolute top-0 bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal
              ? "hidden"
              : "absolute w-[340px] h-[240px] flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#F1000029]">
              <RiDeleteBin6Line size={30} color="red" />
            </div>
          </div>

          {/* Title */}
          <div className="mb-[50px]">
            <p className="text-white font-bold text-xl text-center">
              Ce produit sera définitiviment retiré ?
            </p>
          </div>

          <div className="px-[10%] w-full absolute flex justify-between bottom-5">
            {/* yes  */}
            <button
              onClick={() => {
                deleteProduct();
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }}
              className="w-full h-10 bg-red-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Oui</p>
            </button>

            {/* Not  */}
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className="w-full h-10 bg-blue-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Non</p>
            </button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default StoreProductList;
